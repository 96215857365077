import React from "react";
import PayoutAction from "./Payout.action";

interface TableProps {
  tableHeading: string[];
  tableKeys: string[];
  tableData: any[];
  selectedRow: any;
  handleMarkPayoutMarking: any;
}

const PayoutTable: React.FC<TableProps> = ({ tableHeading, tableKeys, tableData, selectedRow, handleMarkPayoutMarking }) => {
  return (
    <div
      className="overflow-x-scroll w-full mr-6"
      style={{
        scrollbarWidth: "thin",
        scrollbarColor: "#D7DBEC transparent",
        msOverflowStyle: "none",
        overflowX: "scroll",
        overflowY: "hidden",
        borderRadius: "16px"
      }}
    >
      <table className="w-full">
        <thead>
          <tr className="border-b-2 whitespace-nowrap">
            {tableHeading.map((data: any, i: number) => {
              if (i == 0) return;
              return (
                <th key={i} className="pr-1 md:pr-3 py-2 md:py-4 text-sm md:text-base ">
                  <div className="flex gap-2 px-4 min-w-32">
                    <p className="font-extrabold">{data}</p>
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className="text-gray-600 w-full text-center">
          {tableData && tableData?.length > 0 ? (
            tableData.map((data, index) => (
              <tr key={index} className="border-b-2">
                {tableKeys.map((key, i) => {
                  if (i === 0) return null;
                  if (key == "action") return <PayoutAction index={index} data={data} selectedRow={selectedRow} handleMarkPayoutMarking={handleMarkPayoutMarking} />;

                  if (["claimAmount", "commisionAmount", "tds", "finalPayableAmount"].includes(key))
                    return (
                      <td key={i} className="text-left pr-6 py-4 ">
                        {data[key] ? (
                          <p className="font-bold">
                            <span className="text-gray-400">₹</span>&nbsp;
                            <span className="text-[#00AC4F]">{Number(data[key]).toFixed(2)}</span>
                          </p>
                        ) : (
                          "-"
                        )}
                      </td>
                    );

                  if (key === "leadNumbers") {
                    return (
                      <td key={i} className="text-left pr-6 py-4 ">
                        {data[key]
                          ? data[key].map((id: any, index: any) => (
                              <p className="whitespace-nowrap" key={index}>
                                {id}
                                <br />
                              </p>
                            ))
                          : "-"}
                      </td>
                    );
                  }

                  let cellValue = data[key];

                  if (key.includes(".")) {
                    const nestedKeys = key.split(".");
                    let nestedData = data;
                    for (const nestedKey of nestedKeys) {
                      nestedData = nestedData[nestedKey];
                      if ([undefined, null].includes(nestedData)) {
                        cellValue = "-";
                        break;
                      } else {
                        cellValue = nestedData;
                      }
                    }
                  }
                  return (
                    <td key={i} className="text-left pr-6 py-4 ">
                      {cellValue || "-"}
                    </td>
                  );
                })}
              </tr>
            ))
          ) : (
            <td colSpan={tableHeading?.length} className="text-center text-xl pt-2">
              No data found
            </td>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default PayoutTable;
