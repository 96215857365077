import React, { useRef, useState, useEffect } from "react";
import Container from "../../component/common/Container";
import Typography from "../../component/semantic/Typography";
import OpenCollapse from "../../assets/icons/basic/openCollapse.svg";
import closeCollapse from "../../assets/icons/basic/closeCollapse.svg";
import { faqContent } from "../../helpers/constants/constants";

const FAQ = () => {
  const [collapsed, setCollapsed] = useState(-1);
  const refs: any = useRef([]);

  useEffect(() => {
    refs.current = refs.current.slice(0, faqContent?.length);
  }, [faqContent?.length]);

  return (
    <>
      <Typography variant="h1" className="pl-6 pb-4 block md:hidden">
        Frequently Asked Questions
      </Typography>
      <Container>
        <Typography variant="h1" className="text-center hidden md:block">
          Frequently Asked Questions
        </Typography>
        <div className="-mt-4 md:mt-4 custom-polifyx-scrollbar overflow-y-scroll h-[42rem] md:h-[32rem] mb-10 pr-3">
          {faqContent.map((content, key) => {
            return (
              <div className={`border-2 cursor-pointer md:border-0 rounded-xl mx-auto mt-5 ${collapsed == key ? "bg-white shadow-all" : "bg-[#F4F5FA]"}`} key={key}>
                <header
                  role="button"
                  onClick={() => {
                    collapsed === key ? setCollapsed(-1) : setCollapsed(key);
                  }}
                  className={` flex justify-between items-center  rounded-xl  py-3 px-3  md:py-4 md:px-8 duration-500`}
                >
                  <p id="question" className="font-extrabold md:font-bold text-14px md:text-base">
                    {content.question}
                  </p>
                  <img src={collapsed === key ? closeCollapse : OpenCollapse} className="cursor-pointer" />
                </header>
                <div className="overflow-y-hidden transition-all" style={{ height: collapsed === key ? refs.current[key]?.offsetHeight || 0 : 0 }}>
                  <div className="pt-2 p-4" ref={(el) => (refs.current[key] = el)}>
                    <Typography className="text-sm pl-0 md:pl-4 text-gray-600 md:text-base  w-[95%]">{content.answer}</Typography>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </Container>
    </>
  );
};

export default FAQ;
