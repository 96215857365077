import React, { FC } from "react";
import Typography from "../semantic/Typography";

interface TabProps {
  tabs: string[];
  setActiveTab: any;
  activeTab: number;
}

const SubcategoryTab: FC<TabProps> = ({ tabs, setActiveTab, activeTab }) => {
  return (
    <div className={`bg-gray-200 rounded-lg flex p-1 transition ${tabs?.length > 2 && "overflow-x-scroll md:overflow-auto"}`}>
      {tabs.map((tab, key) => (
        <Typography key={key} variant="h2" className={`${activeTab == key + 1 && "bg-polyfixTheme"} py-2 px-12 ${tabs?.length > 2 && "px-5 md:px-12 whitespace-nowrap"} transition rounded-lg cursor-pointer `} onClick={() => setActiveTab(key + 1)}>
          {tab}
        </Typography>
      ))}
    </div>
  );
};

export default SubcategoryTab;
