import React, { useEffect, useState } from "react";
import Container from "../../component/common/Container";
import Typography from "../../component/semantic/Typography";
import Spinner from "../../component/common/Spinner";
import ActionTable from "./component/ActionTable";
import { actionModalTypes } from "../../helpers/constants/interfaceTypes";
import Pagination from "../../component/common/Pagination";
import { useAppDispatch, useAppSelector } from "../../hooks/redux_toolkit";
import { getAllUserPendingContractThunk, sendPartnerContractThunk, updateAndSendContractThunk, signPartnerContractThunk } from "../../store/thunk/PendingContract.thunk";
import ConfirmationModal from "./component/ConfirmationModal";
import { updateZeroPercentManual, updatePromoteLevelManual } from "../../store/slices/PendingContractSlice";
import { toast } from "react-toastify";
import ViewContract from "./component/Contract";
import Filter from "./component/Filter";
import GenerateContractIcon from "../../assets/icons/pendingContract/Generate_Contract_Icon.svg";
import ViewContractIcon from "../../assets/icons/pendingContract/View_Contract_Icon.svg";
import SendContractIcon from "../../assets/icons/pendingContract/Send_Contract_Icon.svg";

const PendingContract = () => {
  const pendingContractData = useAppSelector((state) => state.rootReducer.PendingContract);
  const [selectedRow, setSelectedUser] = useState<any>();
  const [currentPage, setCurrentPage] = useState(1);
  const [generateContractConfirmationModal, setGenerateContractConfirmationModal] = useState(false);
  const [viewContractConfirmationModal, setViewContractConfirmationModal] = useState(false);
  const [sendContractConfirmationModal, setSendContractConfirmationModal] = useState(false);
  const [confirmationModalText, setConfirmationModalText] = useState("Are you sure?");
  const [filter, setFilter] = useState({ search: "", startDate: "", endDate: "" });

  const handleFilterChange = (newFilter: { search: string; startDate: string; endDate: string }) => {
    setFilter(newFilter);
  };

  const pageSize = 20;

  const dispatch = useAppDispatch();

  const actions: actionModalTypes = [
    {
      image: <img src={GenerateContractIcon} className="cursor-pointer w-6 h-6" />,
      tooltip: "Generate Contract",
      onClickFunction: () => {
        setConfirmationModalText("Are you sure you want to generate the contract?");
        setGenerateContractConfirmationModal(true);
      }
    },
    {
      image: <img src={ViewContractIcon} className="cursor-pointer w-6 h-6" />,
      tooltip: "View Contract",
      onClickFunction: () => {
        setViewContractConfirmationModal(true);
      }
    },
    {
      image: <img src={SendContractIcon} className="cursor-pointer w-6 h-6" />,
      tooltip: "Send Contract",
      onClickFunction: () => {
        setConfirmationModalText("Are you sure you want to send the contract to the Partner?");
        setSendContractConfirmationModal(true);
      }
    }
  ];

  const tableHeaders = ["pendingContract", "S.No", "Name", "Mobile", "Date", "Hierarchy", "Contract %", "0% Contract", "Promote to Associate?", "Actions"];
  const tableKeys = ["pendingContract", "sn", "name", "mobile", "createdOn", "hierarchicalLevel", "partnerContractSignedRate", "zeroPercentPartner", "zeroPercentPartner", "action"];

  const generateContractButtonHandler = () => {
    try {
      const formData = {
        value: selectedRow.partnerContractSignedRate ? selectedRow.partnerContractSignedRate : "0",
        userId: selectedRow._id,
        hierarchicalLevel: selectedRow.hierarchicalLevel,
        zeroPercentPartner: selectedRow.zeroPercentManual ? selectedRow.zeroPercentManual : false,
        promoteLevel: selectedRow.promoteLevelManual ? selectedRow.promoteLevelManual : false
      };
      setGenerateContractConfirmationModal(false);

      dispatch(updateAndSendContractThunk({ ...formData }))
        .unwrap()
        .then((data) => {
          if (data?.success) toast.success(data.message || "Success");
        });
      // eslint-disable-next-line
    } catch (error: any) {
      // console.log(error);
      // toast(error.toString());
    }
  };

  const sendContractButtonHandler = () => {
    try {
      const formData = {
        userId: selectedRow._id
      };
      setSendContractConfirmationModal(false);

      dispatch(sendPartnerContractThunk({ ...formData }))
        .unwrap()
        .then((data) => {
          toast.success(data.message || "Success");
        });
    } catch (error: any) {
      toast(error.toString());
    }
  };

  const updateZeroPercentManualFunction = (index: number) => {
    try {
      dispatch(updateZeroPercentManual(index));
    } catch (error: any) {
      toast(error.toString());
    }
  };

  const updatePromoteLevelManualFunction = (index: number) => {
    try {
      dispatch(updatePromoteLevelManual(index));
    } catch (error: any) {
      toast(error.toString());
    }
  };

  const signPartnerContract = () => {
    try {
      const formData = {
        userId: selectedRow._id
      };

      dispatch(signPartnerContractThunk({ ...formData }))
        .unwrap()
        .then((data) => {
          toast.success(data.message || "Success");
          dispatch(getAllUserPendingContractThunk({ page: currentPage, size: pageSize, search: filter.search, startDate: filter.startDate, endDate: filter.endDate }));
        });
    } catch (error: any) {
      toast(error.toString());
    }
  };

  useEffect(() => {
    dispatch(getAllUserPendingContractThunk({ page: filter?.search ? 1 : currentPage, size: pageSize, search: filter.search, startDate: filter.startDate, endDate: filter.endDate }));
  }, [currentPage, filter]);

  return (
    <>
      <Filter onFilterChange={handleFilterChange} setFilter={setFilter} />
      <Container>
        <Container customClassName="mt-6">
          <Typography variant="h1">Pending Contracts</Typography>
          <Typography className="text-gray-600 mb-6">List of all Pending Partners, Associates and Advisors</Typography>
          {pendingContractData.loading ? <Spinner /> : <ActionTable updatePromoteLevelManualFunction={updatePromoteLevelManualFunction} updateZeroPercentManualFunction={updateZeroPercentManualFunction} actions={actions} selectedRow={setSelectedUser} tableData={pendingContractData.usersList?.data} tableHeading={tableHeaders} tableKeys={tableKeys} paginationData={{ limit: pageSize, page: currentPage }} />}
          <span className="my-2">&nbsp;</span>
          {pendingContractData?.usersList?.data && <Pagination currentPage={currentPage} itemsPerPage={pageSize} setCurrentPage={setCurrentPage} totalItems={pendingContractData?.usersList?.count || 0} />}
          <ConfirmationModal confirmationModal={generateContractConfirmationModal} setConfirmationModal={setGenerateContractConfirmationModal} successButtonHandler={generateContractButtonHandler} text={confirmationModalText} />
          <ConfirmationModal confirmationModal={sendContractConfirmationModal} setConfirmationModal={setSendContractConfirmationModal} successButtonHandler={sendContractButtonHandler} text={confirmationModalText} />
          <ViewContract selectedRow={selectedRow} confirmationModal={viewContractConfirmationModal} setConfirmationModal={setViewContractConfirmationModal} signPartnerContract={signPartnerContract} />
        </Container>
      </Container>
    </>
  );
};

export default PendingContract;
