import React, { useEffect, useState } from "react";
import FormInput from "../../../component/semantic/FormInput";
import Select from "react-select";
import { polyfixSelect } from "../../../helpers/constants/styles";
import Typography from "../../../component/semantic/Typography";
import { useAppSelector } from "../../../hooks/redux_toolkit";
import { getCurrentUser } from "helpers/currentUser";

interface ReportProps {
  formProps: any;
}

const KypReport: React.FC<ReportProps> = ({ formProps }) => {
  const currentUser = getCurrentUser();
  const [isAdmin, setAdmin] = useState(true);
  const [userListArr, setUserListArr] = useState<any>([]);
  const userList = useAppSelector((state) => state.rootReducer.common.userDropdownList);

  useEffect(() => {
    if (userList.data) setUserListArr(userList.data);
  }, [userList]);

  const handlePartnerChange = (option: any) => {
    formProps.setFieldValue("userId", option._id);
  };

  useEffect(() => {
    if (currentUser?.userType !== "Manager") {
      setAdmin(false);
    }
  }, [currentUser]);

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
      <FormInput name="startDate" max="true" type="date" label="Start Date" formProps={formProps} />
      <FormInput name="endDate" min={formProps.values.startDate} type="date" label="End Date" formProps={formProps} />
      {isAdmin ? (
        <span>
          <Typography className="font-normal mb-1">Select Partner</Typography>
          <Select options={[{ _id: "all", name: "All" }, ...userListArr]} getOptionLabel={(option) => option.name} getOptionValue={(option) => option._id} className="w-full " styles={polyfixSelect} name="userId" onChange={handlePartnerChange} placeholder="Select Partner" />
        </span>
      ) : (
        ""
      )}
    </div>
  );
};

export default KypReport;
