import { useRouteError, useNavigate } from "react-router-dom";

const UnexpectedError = () => {
  const error = useRouteError();
  const navigate = useNavigate();
  console.error("Caught by Router:", error);

  return (
    <div className="h-screen flex flex-col items-center justify-center text-center px-4">
      <div className="p-8 rounded-2xl shadow-lg max-w-md border border-gray-300">
        <h1 className="text-3xl font-bold text-red-500 mb-4 animate-pulse">Oops! Something went wrong.</h1>
        <p className="text-gray-700 mb-4">{(error as Error)?.message || "An unexpected error occurred. Please try again later."}</p>

        <div className="flex gap-4 mt-6 justify-center">
          <button onClick={() => navigate(-1)} className="px-5 py-2 bg-blue-500 hover:bg-blue-600 text-white font-medium rounded-lg shadow-md transition-all duration-200">
            Go Back
          </button>
          <button onClick={() => window.location.reload()} className="px-5 py-2 bg-gray-200 hover:bg-gray-300 text-gray-800 font-medium rounded-lg shadow-md transition-all duration-200">
            Reload
          </button>
        </div>
      </div>
    </div>
  );
};

export default UnexpectedError;
