import React, { useEffect, useRef, useState, KeyboardEvent } from "react";
import Container from "../../component/common/Container";
import Typography from "../../component/semantic/Typography";
import { FaPhone } from "react-icons/fa6";
import ButtonOutline from "../../component/semantic/Button";
import { MdEmail } from "react-icons/md";
import UserDetails from "./component/userDetails";
import { useDispatch } from "react-redux";
import { getAllUser, getUsersChildren, searchUserThunk } from "../../store/thunk/UserManagement.thunk";
import { useAppSelector } from "../../hooks/redux_toolkit";
import { getCurrentUser } from "../../helpers/currentUser";
import CustomInput from "../../component/semantic/Input";
import InputIcon from "../../component/semantic/InputIconMob";
import Spinner from "../../component/common/Spinner";
import arrow from "../../assets/icons/basic/userArrow.svg";
import Divide from "../../component/semantic/Divide";

const UserManagement: React.FC = () => {
  const userData: any = useAppSelector((state) => state.rootReducer.userManagement);

  const [userInput, setUserInput] = useState<string>("");
  const [collapsed, setCollapsed] = useState<number>(-1);

  const refs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    refs.current = refs.current.slice(0, userData.userDataStats.data?.length);
  }, [userData.userDataStats.data]);
  const dispatch = useDispatch();
  const currentUser = getCurrentUser();

  useEffect(() => {
    getAllUserHandler();
  }, []);

  const getAllUserHandler = () => {
    dispatch(getAllUser({ page: 0, size: 5, hierarchicalLevel: 1, parentId: currentUser?._id }))
      .unwrap()
      .then((data: any) => {
        const ids = data.data.map((ele: any) => ele._id);
        dispatch(getUsersChildren({ children: ids }));
      });
    setUserInput("");
  };

  const searchUserHandler = () => {
    if (userInput) {
      setCollapsed(-1);
      dispatch(searchUserThunk({ userId: currentUser._id, query: userInput }))
        .unwrap()
        .then((data: any) => {
          const ids = data.data.map((ele: any) => {
            return ele._id;
          });
          dispatch(getUsersChildren({ children: ids }));
        });
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      searchUserHandler();
    }
  };

  return (
    <>
      <p className="font-extrabold text-xl block md:hidden px-2 mx-4 mb-4">User Management</p>
      <Container>
        <div className="hidden md:flex justify-center items-end gap-2 w-[60%]">
          <CustomInput parentClassName="w-full flex gap-1 flex-col item-center" onKeyDown={handleKeyDown} onChange={(e) => setUserInput(e.target.value)} value={userInput} label="Search for Customer" placeholder="Name/Mobile/Email" />
          <ButtonOutline type="button" className={`bg-polyfixTheme  w-32 mb-2`} title="Search" onClick={searchUserHandler} />
          <ButtonOutline title="Clear Filter" color="polyfixTheme" className="hover:bg-polyfixTheme w-32 mb-2 hover:text-white" onClick={getAllUserHandler} />
        </div>
        <div className="flex md:hidden w-full flex-col gap-1">
          <Typography className="text-polyfixTheme text-end" onClick={getAllUserHandler}>
            Clear All
          </Typography>
          <InputIcon placeholder="Name/Mobile/Email" onClickFunction={searchUserHandler} value={userInput} onChange={(e) => setUserInput(e.target.value)} />
        </div>
      </Container>

      <Container>
        <Typography variant="h1" className="text-left font-semibold">
          Manage your users
        </Typography>
        <Typography variant="h2" className="text-left font-medium text-gray-500">
          View and Manage your subordinates
        </Typography>
        <div className="-mt-4 md:mt-4 cursor-pointer custom-polifyx-scrollbar overflow-y-scroll h-[42rem] md:h-[32rem] mb-10 pr-0 md:pr-3">
          {userData.loading ? (
            <Spinner />
          ) : (
            userData.userDataStats.data &&
            userData.userDataStats.data.map((ele: any, key: any) => {
              return (
                <div key={key} onClick={() => setCollapsed(collapsed === key ? -1 : key)} className={`border-2 relative ${collapsed === key ? "bg-white shadow-all" : "bg-[#F4F5FA]"} overflow-hidden  py-3 px-3 rounded-xl mt-5 md:py-4 md:px-8`}>
                  <div className={`${!ele.data.userData.isAgreed || !ele.data.userData.isVerified ? "" : "hidden"} absolute -top-1 text-xs bg-[#FEF0E5] px-2 pt-1 rounded font-semibold  text-[#FC6B03]`}>Awaiting Verification & Contract</div>
                  <div className={`flex relative justify-between cursor-pointer md:border-0 mx-auto overflow-hidden transition-height duration-500 ${(ele.data.userData.isAgreed || ele.data.userData.isAgreed) && "mt-2 md:mt-0"}`}>
                    <div className="flex flex-col md:flex-row justify-start md:justify-between gap-0 md:gap-3 items-start md:items-center ">
                      <div className="flex justify-between items-center">
                        <p className="text-ls font-bold ">{ele.data.userData.name}</p>
                        <img src={arrow} className={`text-gray-400 block md:hidden absolute right-0 transition-all text-lg ${collapsed === key ? "rotate-180" : "rotate-0"}`} />
                      </div>
                      <p className="flex gap-3 items-center">
                        <MdEmail className="text-gray-600" />
                        <span>{ele.data.userData.email}</span>
                      </p>
                      <p className="flex gap-3 items-center">
                        <FaPhone className="text-gray-600 ml-[2px]" size={12} />
                        <span>{ele.data.userData.mobile}</span>
                      </p>
                    </div>
                    <img src={arrow} className={`text-gray-400 hidden md:block transition-all text-lg ${collapsed === key ? "rotate-180" : "rotate-0"}`} />
                  </div>
                  <div className="overflow-hidden transition-all" style={{ height: collapsed === key ? refs.current[key]?.scrollHeight || 0 : 0 }} ref={(el) => (refs.current[key] = el)}>
                    <Divide className="-mx-16 mt-4" />
                    <UserDetails data={ele} />
                  </div>
                </div>
              );
            })
          )}
        </div>
      </Container>
    </>
  );
};

export default UserManagement;
