import axios, { AxiosError, AxiosResponse } from "axios";
import { toast } from "react-toastify";
axios.interceptors.request.use(
  (config: any) => {
    const tokenString = localStorage.getItem("userInfo");
    let token: string | null = null;

    if (tokenString) {
      const userInfo = JSON.parse(tokenString);
      if (userInfo && typeof userInfo === "object" && "token" in userInfo) {
        token = userInfo.token;
      } else {
        // eslint-disable-next-line
        console.error("Invalid userInfo format or missing token property");
      }
    }
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error: AxiosError) => {
    // eslint-disable-next-line
    console.error("Request interceptor error:", error);
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    let errorMessage;
    if (error.response && error.response.data) {
      errorMessage = (error?.response?.data as any)?.message;
    } else {
      errorMessage = error.message;
    }
    const maxLength = 100;
    if (errorMessage?.length > maxLength) {
      errorMessage = "Something went wrong";
    }

    if (errorMessage == "No Auth token found") {
      toast.error("Please Login Here");
    } else toast.error(errorMessage);
    if (errorMessage == "It seems like you need to login first") {
      localStorage.removeItem("userInfo");
      if (process.env.REACT_APP_ENV === "prod") window.location.href = "https://partnersadmin.insurancesamadhan.com/login";
      else window.location.href = `https://partnersadmin.${process.env.REACT_APP_ENV}.insurancesamadhan.com/login`;
    }
    return Promise.reject(error);
  }
);

export default axios;
