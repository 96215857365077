import React, { useEffect, useRef, useState, KeyboardEvent } from "react";
import { activeDeactiveUserThunk, addOrUpdatePolicyTypeThunk, getAllUser, getAllUserCount, getUsersChildren, markImpThunk, searchUserThunk, sendContractThunk, sendEmailVerification, singleV2 } from "../../store/thunk/UserManagement.thunk";
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import { getCurrentUser } from "../../helpers/currentUser";
import { s3Url } from "../../helpers/constants/defaultUrls";
import { useAppDispatch, useAppSelector } from "../../hooks/redux_toolkit";
import Container from "../../component/common/Container";
import CustomInput from "../../component/semantic/Input";
import ButtonOutline from "../../component/semantic/Button";
import Typography from "../../component/semantic/Typography";
import InputIcon from "../../component/semantic/InputIconMob";
import Spinner from "../../component/common/Spinner";
import { MdBlock, MdEmail } from "react-icons/md";
import { FaCheck, FaPhone } from "react-icons/fa";
import Divide from "../../component/semantic/Divide";
import PartnerDetails from "./component/partnerDetails";
import arrow from "../../assets/icons/basic/userArrow.svg";
import Pagination from "../../component/common/Pagination";
import Menu from "../../component/common/Menu";
import BlockUser from "./component/Dialogs/BlockUser";
import SelectPolicy from "./component/Dialogs/SelectPolicyType";
import EditUserDetails from "./component/Dialogs/EditUserDetails";
import ViewContract from "./component/Dialogs/ViewContract";

import contractIcon from "../../assets/icons/partnerManagement/contract.svg";
import sendContractIcon from "../../assets/icons/partnerManagement/sendContract.svg";
import editIcon from "../../assets/icons/partnerManagement/edit.svg";
import downloadIcon from "../../assets/icons/partnerManagement/downloadDoc.svg";
import filterIcon from "../../assets/icons/partnerManagement/filter.svg";
import sendVerification from "../../assets/icons/partnerManagement/sendVerification.svg";
// import cobranding from "../../assets/icons/partnerManagement/cobranding.svg";
import { BsThreeDots } from "react-icons/bs";
import { userUpdate } from "../../store/thunk/Profile.thunk";

interface UserData {
  _id: string;
  name: string;
  email: string;
  mobile: string;
  isVerified: boolean;
  isAgreed: boolean;
  isActive: boolean;
  isImportant: boolean;
}

interface UserDetailsData {
  data: {
    userData: UserData;
  };
  children?: UserDetailsData[];
}

interface UserState {
  loading: boolean;
  userDataStats: {
    data: UserDetailsData[];
  };
}

interface UserBlockState {
  status: string;
  userId: string;
  deActReason: string;
}

const PartnerManagement: React.FC = () => {
  const userData: UserState = useAppSelector((state) => state.rootReducer.userManagement);

  const [userInput, setUserInput] = useState<string>("");
  const [collapsed, setCollapsed] = useState<number>(-1);

  const [usersCount, setUsersCount] = useState<number>(0);
  const [blockUserData, setBlockUserData] = useState<UserBlockState>({
    status: "",
    userId: "",
    deActReason: ""
  });

  const pageSize = 5;
  const [currentPage, setCurrentPage] = useState(1);

  const [viewBlockModal, setViewBlockModal] = useState<boolean>(false);
  const [selectedUserData, setUserData] = useState<object>({});
  const [selectPolicyModal, setSelectPolicyModal] = useState<boolean>(false);
  const [editUserModal, setEditUserModal] = useState<boolean>(false);
  const [viewContractModal, setViewContractModal] = useState<boolean>(false);

  const [showMenu, setShowMenu] = useState<number>(-1);

  const refs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    refs.current = refs.current.slice(0, userData.userDataStats.data?.length);
  }, [userData.userDataStats.data]);

  const dispatch = useAppDispatch();
  const currentUser = getCurrentUser();

  const getAllUserHandler = () => {
    dispatch(getAllUser({ page: currentPage - 1, size: pageSize, hierarchicalLevel: 0 }))
      .unwrap()
      .then((data: any) => {
        // if (data?.success) toast.success(data.message);
        const ids = data?.data.map((ele: any) => ele._id);
        dispatch(getUsersChildren({ children: ids }));
      });
    setUserInput("");
    setShowMenu(-1);
  };

  const getAllUsersCount = () => {
    dispatch(getAllUserCount({ hierarchicalLevel: 0, userType: "Vendor" }))
      .unwrap()
      .then((data: any) => {
        // if (data?.success) toast.success(data.message);
        setUsersCount(data?.data);
      });
  };

  const searchUserHandler = () => {
    if (userInput) {
      setCollapsed(-1);
      dispatch(searchUserThunk({ userId: currentUser._id, query: userInput ? userInput.trim() : "" }))
        .unwrap()
        .then((data: any) => {
          // if (data?.success) toast.success(data.message);
          const ids = data?.data.map((ele: any) => {
            return ele._id;
          });
          dispatch(getUsersChildren({ children: ids }));
        });
      setShowMenu(-1);
    }
  };

  const downloadContract = (userData: any) => {
    dispatch(singleV2({ _id: userData._id }))
      .unwrap()
      .then((data: any) => {
        !data?.data?.isAgreed ? toast.error("Contract not signed !") : toast.success(data.message);

        if (data?.data?.contractFile) {
          const contractLink = document.createElement("a");
          contractLink.href = s3Url + `${data.data?._id}/${data.data?.contractFile}`;
          contractLink.click();
        }
      });
  };

  const sendContract = (userData: any) => {
    if (userData?.isAgreed) {
      toast.success("User has already accepted contract");
      return;
    }

    dispatch(sendContractThunk({ userId: userData._id }))
      .unwrap()
      .then((data: any) => {
        if (data?.success) toast.success(data.message);
        else toast.error(data?.message);
      });
  };

  const sendEmailVerificationLink = (userData: any) => {
    dispatch(sendEmailVerification({ userId: userData._id }))
      .unwrap()
      .then((data: any) => {
        toast.success(data?.message);
      });
  };

  const markImportant = (e: any, userId: string) => {
    dispatch(markImpThunk({ isImportant: e.target.checked, _id: userId }))
      .unwrap()
      .then((data: any) => {
        if (data.success) toast.success(data.message);

        if (userInput != "") searchUserHandler();
        else getAllUserHandler();
      });
  };

  const userStatusHandler = (id: string, status: string, deActReason: string) => {
    if (status === "disable" && deActReason == "") {
      toast.error("Please, Enter valid reason !");
      return;
    }
    dispatch(activeDeactiveUserThunk({ userId: id, status, deActReason }))
      .unwrap()
      .then((data: any) => {
        if (data.success) toast.success(data.message);
        if (userInput != "") searchUserHandler();
        else getAllUserHandler();
      });
  };

  const addOrUpdatePolicyTypes = (selectedPolicyTypes: any, userData: object) => {
    dispatch(addOrUpdatePolicyTypeThunk({ selectedPolicyTypes, userData }))
      .unwrap()
      .then((data: any) => {
        if (data.status === 200) toast.success(data.message);

        if (userInput != "") searchUserHandler();
        else getAllUserHandler();
      });
  };

  const updateUserDetails = (values: any) => {
    dispatch(userUpdate(values))
      .unwrap()
      .then((data: any) => {
        if (data.status === 200) toast.success(data.message);

        if (userInput != "") searchUserHandler();
        else getAllUserHandler();
      });
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      searchUserHandler();
    }
  };

  useEffect(() => {
    getAllUserHandler();
    getAllUsersCount();
  }, [currentPage]);

  const actions: any = [
    { image: <img src={downloadIcon} className="cursor-pointer" />, tooltip: "Download Signed Contract", onClickFunction: (data: any) => downloadContract(data) },
    { image: <img src={contractIcon} className="cursor-pointer" />, tooltip: "View Contract", onClickFunction: () => setViewContractModal(true) },
    { image: <img src={sendContractIcon} className="cursor-pointer" />, tooltip: "Send Contract Email", onClickFunction: (data: any) => sendContract(data) },
    { image: <img src={sendVerification} className="cursor-pointer" />, tooltip: "Send Email Verification Link", onClickFunction: (data: any) => sendEmailVerificationLink(data) },
    { image: <img src={filterIcon} className="cursor-pointer" />, tooltip: "Select Specific Policy Type", onClickFunction: () => setSelectPolicyModal(true) },
    // { image: <img src={cobranding} className="cursor-pointer" />, tooltip: "Cobranding Details", onClickFunction: () => true },
    { image: <img src={editIcon} className="cursor-pointer" />, tooltip: "Edit User Data", onClickFunction: () => setEditUserModal(true) }
  ];

  return (
    <>
      <p className="font-extrabold text-xl block md:hidden px-2 mx-4 mb-4">Partner Management</p>
      <Container>
        <div className="hidden md:flex justify-center items-end gap-2 w-[60%]">
          <CustomInput parentClassName="w-full flex gap-1 flex-col item-center" onKeyDown={handleKeyDown} onChange={(e) => setUserInput(e.target.value)} value={userInput} label="Search for Partners" placeholder="Name/Mobile/Email" />
          <ButtonOutline type="button" className={`bg-polyfixTheme  w-32 mb-2`} title="Search" onClick={searchUserHandler} />
          <ButtonOutline title="Clear Filter" color="polyfixTheme" className="hover:bg-polyfixTheme hover:text-white w-32 mb-2" onClick={getAllUserHandler} />
        </div>
        <div className="flex md:hidden w-full flex-col gap-1">
          <Typography className="text-polyfixTheme text-end" onClick={getAllUserHandler}>
            Clear All
          </Typography>
          <InputIcon placeholder="Name/Mobile/Email" onClickFunction={searchUserHandler} value={userInput} onChange={(e) => setUserInput(e.target.value)} />
        </div>
      </Container>
      <br />
      <Container>
        <Typography variant="h1" className="text-left font-semibold">
          Manage your Partners
        </Typography>
        <Typography variant="h2" className="text-left font-medium text-gray-500">
          View and manage your subordinates
        </Typography>
        <div className="mt-4 cursor-pointer custom-polifyx-scrollbar overflow-y-scroll h-[42rem] md:h-[32rem] mb-5 pr-3">
          {userData.loading ? (
            <Spinner />
          ) : (
            userData.userDataStats.data &&
            userData.userDataStats.data.map((ele, key) => {
              return (
                <div key={key} className={`border-2 relative ${collapsed === key ? "bg-white shadow-all" : "bg-[#F4F5FA]"} overflow-hidden  py-3 px-3 rounded-xl mt-5 md:py-4 md:px-8`}>
                  <div className={`${!ele.data.userData.isAgreed || !ele.data.userData.isVerified ? "" : "hidden"} absolute -top-1 text-xs bg-[#FEF0E5] px-2 pt-1 rounded font-semibold  text-[#FC6B03]`}>Awaiting Verification & Contract</div>
                  <div className={`flex relative justify-between cursor-pointer md:border-0 mx-auto overflow-hidden transition-height duration-500 ${ele.data.userData.isAgreed && "mt-2 md:mt-0"}`}>
                    <div className="flex flex-col md:flex-row justify-start md:justify-between gap-0 md:gap-3 items-start md:items-center">
                      <p className="text-ls font-bold mt-2 md:mt-0">{ele.data.userData.name}</p>
                      <p className="flex gap-2 md:gap-3 items-center">
                        <MdEmail className="text-gray-600" />
                        <span className="text-sm md:text-base">{ele.data.userData.email}</span>
                      </p>
                      <p className="flex gap-2 md:gap-3 items-center">
                        <FaPhone className="text-gray-600 ml-[2px] rotate-90" size={12} />
                        <span>{ele.data.userData.mobile}</span>
                      </p>
                    </div>

                    <div className="flex absolute right-8 md:hidden items-center">
                      {ele.data.userData.isActive ? (
                        <>
                          {!ele.data.userData.isAgreed || !ele.data.userData.isVerified ? (
                            ""
                          ) : (
                            <ButtonOutline
                              title="Block"
                              color="[#ff0000]"
                              icon={<MdBlock />}
                              className="hover:bg-[#ff0000] hover:text-[#FEFFFE] bg-[#fee0e9] text-[#ff0000] border-[#ff0000] hidden md:flex"
                              onClick={(e: any) => {
                                e.stopPropagation();
                                setBlockUserData({
                                  ...blockUserData,
                                  status: "disable",
                                  userId: ele.data.userData._id
                                });
                                setViewBlockModal(true);
                              }}
                            />
                          )}
                          <div
                            onClick={(e: any) => {
                              e.stopPropagation();
                              setBlockUserData({
                                ...blockUserData,
                                status: "disable",
                                userId: ele.data.userData._id
                              });
                              setViewBlockModal(true);
                            }}
                            className="border-2 text-[#ff0000] bg-[#fee0e9] border-[#ff0000] rounded-md mr-2 block md:hidden"
                          >
                            <MdBlock className="m-2" />
                          </div>
                        </>
                      ) : (
                        <>
                          {!ele.data.userData.isAgreed || !ele.data.userData.isVerified ? (
                            ""
                          ) : (
                            <ButtonOutline
                              title="Active"
                              color="[#008000]"
                              icon={<FaCheck />}
                              className="hover:bg-[#008000] hover:text-[#FEFFFE] bg-[#cdf7b7] text-[#008000] border-[#008000] hidden md:flex"
                              onClick={(e: any) => {
                                e.stopPropagation();
                                userStatusHandler(ele.data.userData._id, "activate", "");
                              }}
                            />
                          )}
                          <div
                            onClick={(e) => {
                              e.stopPropagation();
                              userStatusHandler(ele.data.userData._id, "activate", "");
                            }}
                            className="border-2  bg-[#cdf7b7] text-[#008000] border-[#008000] rounded-md mr-2 block md:hidden"
                          >
                            <FaCheck className="m-2" />
                          </div>
                        </>
                      )}
                      <img src={arrow} className={`text-gray-400 hidden md:block transition-all text-lg ${collapsed === key ? "rotate-180" : "rotate-0"}`} />
                    </div>

                    <div className="hidden md:flex items-center">
                      {ele.data.userData.isActive ? (
                        <>
                          {!ele.data.userData.isAgreed || !ele.data.userData.isVerified ? (
                            ""
                          ) : (
                            <ButtonOutline
                              title="Block"
                              color="[#ff0000]"
                              icon={<MdBlock />}
                              className="hover:bg-[#ff0000] hover:text-[#FEFFFE] bg-[#fee0e9] text-[#ff0000] border-[#ff0000] hidden md:flex"
                              onClick={(e: any) => {
                                e.stopPropagation();
                                setBlockUserData({
                                  ...blockUserData,
                                  status: "disable",
                                  userId: ele.data.userData._id
                                });
                                setViewBlockModal(true);
                              }}
                            />
                          )}
                          <div
                            onClick={(e: any) => {
                              e.stopPropagation();
                              setBlockUserData({
                                ...blockUserData,
                                status: "disable",
                                userId: ele.data.userData._id
                              });
                              setViewBlockModal(true);
                            }}
                            className="border-2 text-[#ff0000] bg-[#fee0e9] border-[#ff0000] rounded-md mr-2 block md:hidden"
                          >
                            <MdBlock className="m-2" />
                          </div>
                        </>
                      ) : (
                        <>
                          {!ele.data.userData.isAgreed || !ele.data.userData.isVerified ? (
                            ""
                          ) : (
                            <ButtonOutline
                              title="Active"
                              color="[#008000]"
                              icon={<FaCheck />}
                              className="hover:bg-[#008000] hover:text-[#FEFFFE] bg-[#cdf7b7] text-[#008000] border-[#008000] hidden md:flex"
                              onClick={(e: any) => {
                                e.stopPropagation();
                                userStatusHandler(ele.data.userData._id, "activate", "");
                              }}
                            />
                          )}
                          <div
                            onClick={(e) => {
                              e.stopPropagation();
                              userStatusHandler(ele.data.userData._id, "activate", "");
                            }}
                            className="border-2  bg-[#cdf7b7] text-[#008000] border-[#008000] rounded-md mr-2 block md:hidden"
                          >
                            <FaCheck className="m-2" />
                          </div>
                        </>
                      )}
                      <img src={arrow} onClick={() => setCollapsed(collapsed === key ? -1 : key)} className={`text-gray-400 hidden md:block transition-all text-lg ${collapsed === key ? "rotate-180" : "rotate-0"}`} />
                    </div>

                    <img src={arrow} onClick={() => setCollapsed(collapsed === key ? -1 : key)} className={`text-gray-400  block md:hidden absolute  right-0 transition-all text-lg ${collapsed === key ? "rotate-180" : "rotate-0"}`} />
                  </div>
                  <div className="overflow-hidden transition-all" style={{ height: collapsed === key ? refs.current[key]?.scrollHeight || 0 : 0 }} ref={(el) => (refs.current[key] = el)}>
                    <Divide className="-mx-16 mt-4" />
                    <div className="py-5 flex justify-between md:justify-start items-center gap-10">
                      <b>Actions</b>
                      <div className="items-center gap-10 hidden md:flex">
                        {actions.map((data: any, i: number) => {
                          return (
                            <>
                              <div
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setUserData(ele.data.userData);
                                  data.onClickFunction(ele.data.userData);
                                }}
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content={data.tooltip}
                              >
                                {data.image}
                              </div>
                              <Tooltip delayShow={1} key={i} id="my-tooltip" place="bottom" />
                            </>
                          );
                        })}
                        <input
                          type="checkbox"
                          checked={ele.data.userData.isImportant}
                          className="w-4 h-4"
                          onClick={(e) => {
                            e.stopPropagation();
                            markImportant(e, ele.data.userData._id);
                          }}
                        />
                      </div>
                      <div className="md:hidden ">
                        <BsThreeDots
                          className="text-3xl cursor-pointer"
                          onClick={(e: any) => {
                            e.stopPropagation();
                            setShowMenu(showMenu < 0 ? key : -1);
                          }}
                        />
                        <Menu showMenu={showMenu === key} className="w-full top-[10rem] right-0 z-50">
                          <ul>
                            {actions.map((data: any, i: number) => {
                              return (
                                <li
                                  key={i}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setUserData(ele.data.userData);
                                    data.onClickFunction(ele.data.userData);
                                  }}
                                  className="flex items-center gap-3 py-3 pl-2 rounded-md cursor-pointer hover:bg-[#fff9f3] hover:text-polyfixTheme"
                                >
                                  <p className="w-8">{data.image}</p>
                                  <Typography>{data.tooltip}</Typography>
                                </li>
                              );
                            })}
                            <li className="flex items-center gap-2 pl-3 rounded-md cursor-pointer hover:bg-[#fff9f3] hover:text-polyfixTheme">
                              <p className="w-8">
                                <input
                                  type="checkbox"
                                  checked={ele.data.userData.isImportant}
                                  className="w-4 h-4"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    markImportant(e, ele.data.userData._id);
                                  }}
                                />
                              </p>
                              Mark as Important
                            </li>
                          </ul>
                        </Menu>
                      </div>
                    </div>
                    <Divide className="-mx-16" />
                    <PartnerDetails data={ele} />
                  </div>
                </div>
              );
            })
          )}

          {viewBlockModal && <BlockUser showBlockModal={viewBlockModal} setBlockModal={setViewBlockModal} blockUserHandler={() => userStatusHandler(blockUserData.userId, blockUserData.status, blockUserData.deActReason)} setBlockUserData={setBlockUserData} />}
          {selectPolicyModal && <SelectPolicy showSelectPolicyModal={selectPolicyModal} setSelectPolicyModal={setSelectPolicyModal} selectedUserData={selectedUserData} updatePolicyTypes={addOrUpdatePolicyTypes} />}
          {editUserModal && <EditUserDetails showEditUserModal={editUserModal} setEditUserModal={setEditUserModal} selectedUserData={selectedUserData} updateUserData={updateUserDetails} />}
          {viewContractModal && <ViewContract showViewContractModal={viewContractModal} setViewContractModal={setViewContractModal} selectedUserData={selectedUserData} />}
        </div>
        {!userInput && <Pagination itemsPerPage={pageSize} totalItems={usersCount || 0} setCurrentPage={setCurrentPage} currentPage={currentPage} />}
      </Container>
    </>
  );
};

export default PartnerManagement;
