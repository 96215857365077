import React from "react";
import Carousel from "./Carousel";
import Typography from "../../../component/semantic/Typography";
import PolifyxImg1 from "../../../assets/icons/home/polifyx1.svg";
import PolifyxImg2 from "../../../assets/icons/home/polifyx2.svg";
import PolifyxImg3 from "../../../assets/icons/home/polifyx3.svg";
import PolifyxImg5 from "../../../assets/icons/home/polifyx5.svg";
import PolifyxImgIcon from "../../../assets/icons/home/polifyxApp.svg";
import PlayStoreIcon from "../../../assets/icons/home/playStoreIcon.svg";
import AppStoreIcon from "../../../assets/icons/home/appStoreIcon.svg";

function PolifyxPartner() {
  const imagesArr: any = [
    <div key={1} className="flex justify-center h-[90%]">
      <img alt="" src={PolifyxImg1} className=" " />
    </div>,
    <div key={2} className="flex justify-center h-[90%] mx-2">
      <img alt="" src={PolifyxImg2} className="object-bottom " />
    </div>,
    <div key={3} className="flex justify-center h-[90%] mx-2">
      <img alt="" src={PolifyxImg3} className="object-bottom " />
    </div>,
    <div key={4} className="h-[100%] my-auto flex justify-center">
      <div className="bg-[url(/src/assets/icons/home/polifyx4.svg)] p-6 bg-cover rounded-3xl my-auto">
        <div className="w-[90%] mx-auto">
          <Typography className="text-2xl">Download the Polifyx Partner App</Typography>
        </div>
        <div className="flex justify-center py-6">
          <img alt="polifyx-app-icon" src={PolifyxImgIcon} className="w-[84px] h-[84px]" />
        </div>
        <div className="flex justify-between w-full">
          <img alt="play-store-icon" src={PlayStoreIcon} className="w-[45%]" />
          <img alt="play-store-icon" src={AppStoreIcon} className="w-[40%]" />
        </div>
      </div>
    </div>,
    <div key={5} className="flex justify-center h-[90%] mx-2">
      <img alt="" src={PolifyxImg5} />
    </div>
  ];
  // const images: any = [PolifyxImg1, PolifyxImg2, PolifyxImg3, PolifyxImg4, PolifyxImg5];

  return (
    <div className="bg-white relative py-[2%] md:mt-0">
      <div className="w-full text-center">
        <Typography className="text-4xl md:text-6xl" variant="h2">
          Polifyx Partner App
        </Typography>
      </div>

      <div className="flex md:hidden justify-center w-full mt-[10%]">
        <Carousel items={imagesArr} itemClassName={""} />
      </div>
      <div className="hidden md:grid grid-rows-1 grid-flow-col mx-auto px-[10%] mt-10 gap-x-4">
        <div className="row-span-2"></div>
        <div className="grid gap-y-4">
          <img alt="" src={PolifyxImg1} />
          <img alt="" src={PolifyxImg2} />
        </div>
        <div className="row-span-2 ">
          <img alt="" src={PolifyxImg3} />
        </div>
        <div className="row-span-2 w-[70%] text-center">
          <div className="bg-[url(/src/assets/icons/home/polifyx4.svg)] p-6 bg-cover rounded-3xl mb-5">
            <div className="w-[90%] mx-auto">
              <Typography className="text-2xl">Download the Polifyx Partner App</Typography>
            </div>
            <div className="flex justify-center py-6">
              <img alt="polifyx-app-icon" src={PolifyxImgIcon} className="w-[84px] h-[84px]" />
            </div>
            <div className="flex justify-between w-full">
              <img alt="play-store-icon" onClick={() => window.open("https://play.google.com/store/apps/details?id=com.partner.polifyx&hl=en_IN&gl=US", "_blank")} src={PlayStoreIcon} className="w-[45%] cursor-pointer" />
              <img alt="play-store-icon" onClick={() => window.open("https://play.google.com/store/apps/details?id=com.partner.polifyx&hl=en_IN&gl=US", "_blank")} src={AppStoreIcon} className="w-[40%] cursor-pointer" />
            </div>
          </div>
          <img alt="" src={PolifyxImg5} />
        </div>
        <div className="row-span-2"></div>
        {/* {
                    images?.map((res: string) => {
                        return <img alt={"partner-image"} src={res} />
                    })
                } */}
      </div>
    </div>
  );
}

export default PolifyxPartner;
