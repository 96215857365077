import { createSlice } from "@reduxjs/toolkit";
import { downloadReportThunkActiveUser, downloadReportThunkComplaint, downloadReportThunkLead, downloadReportThunkSettled, downloadReportThunkUser, leadStatusThunk, reportDataThunkActiveUser, reportDataThunkComplaint, reportDataThunkLead, reportDataThunkSettled, reportDataThunkUser, reportDataThunkKyp, reportDataThunkKypConsolidated, downloadReportThunkKyp, downloadReportThunkKypConsolidated } from "../thunk/Report.thunk";
import { toast } from "react-toastify";
interface responseData {
  data: any;
}

interface ApiState {
  data: any;
  loading: boolean;
  error: string | null;
  leadStatus: responseData;
  complaintData: responseData;
  leadData: responseData;
  userData: responseData;
  activeUserData?: responseData;
  settledCaseData: responseData;
  kypData: responseData;
  kypConsolidatedData: responseData;
  downloadComplaintReport: any;
  downloadLeadReport: any;
  downloadUserReport: any;
  downloadActiveUserReport: any;
  downloadSettledCaseReport: any;
  downloadKypReport: any;
  downloadKypConsolidatedReport: any;
  downloadReportLoading: boolean;
}

const initialState: ApiState = {
  data: null,
  loading: false,
  error: null,
  leadStatus: { data: "" },
  complaintData: { data: "" },
  leadData: { data: "" },
  userData: { data: "" },
  activeUserData: { data: "" },
  settledCaseData: { data: "" },
  kypData: { data: "" },
  kypConsolidatedData: { data: "" },
  downloadComplaintReport: undefined,
  downloadLeadReport: undefined,
  downloadUserReport: undefined,
  downloadActiveUserReport: undefined,
  downloadSettledCaseReport: undefined,
  downloadKypReport: undefined,
  downloadKypConsolidatedReport: undefined,
  downloadReportLoading: false
};

const referralSlice = createSlice({
  name: "referralSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(leadStatusThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(leadStatusThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.leadStatus = action.payload;
        state.error = null;
      })
      .addCase(leadStatusThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? "An error occurred";
      })

      .addCase(reportDataThunkComplaint.pending, (state) => {
        state.loading = true;
      })
      .addCase(reportDataThunkComplaint.fulfilled, (state, action) => {
        if (action.payload.data?.leads?.length > 0) {
          toast.success("Complaint Report Generated Successfully");
        }
        state.loading = false;
        state.complaintData = action.payload;
        state.error = null;
      })
      .addCase(reportDataThunkComplaint.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? "An error occurred";
      })

      .addCase(reportDataThunkLead.pending, (state) => {
        state.loading = true;
      })
      .addCase(reportDataThunkLead.fulfilled, (state, action) => {
        if (action.payload.data?.leads?.length > 0) {
          toast.success("Lead Report Generated Successfully");
        } else toast.error("No Lead Found");
        state.loading = false;
        state.leadData = action.payload;
        state.error = null;
      })
      .addCase(reportDataThunkLead.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? "An error occurred";
      })

      .addCase(reportDataThunkSettled.pending, (state) => {
        state.loading = true;
      })
      .addCase(reportDataThunkSettled.fulfilled, (state, action) => {
        if (action.payload.data?.cases?.length > 0) {
          toast.success("Settled Case Report Generated Successfully");
        } else toast.error("No Case Found");
        state.loading = false;
        state.settledCaseData = action.payload;
        state.error = null;
      })
      .addCase(reportDataThunkSettled.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? "An error occurred";
      })

      .addCase(reportDataThunkUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(reportDataThunkUser.fulfilled, (state, action) => {
        if (action.payload.data?.users?.length > 0) {
          toast.success("User Report Generated Successfully");
        }
        if (action.meta.arg?.body?.isActive) {
          state.activeUserData = action.payload;
        } else {
          state.userData = action.payload;
        }
        state.loading = false;
        state.error = null;
      })
      .addCase(reportDataThunkUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? "An error occurred";
      })

      .addCase(reportDataThunkKyp.pending, (state) => {
        state.loading = true;
      })
      .addCase(reportDataThunkKyp.fulfilled, (state, action) => {
        if (action.payload.data?.policy.length > 0) {
          toast.success("Kyp Report Generated Successfully");
        }
        state.kypData = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(reportDataThunkKyp.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? "An error occurred";
      })

      .addCase(reportDataThunkKypConsolidated.pending, (state) => {
        state.loading = true;
      })
      .addCase(reportDataThunkKypConsolidated.fulfilled, (state, action) => {
        if (action.payload.data?.policy.length > 0) {
          toast.success("Kyp Report Generated Successfully");
        }
        state.kypConsolidatedData = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(reportDataThunkKypConsolidated.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? "An error occurred";
      })

      .addCase(downloadReportThunkComplaint.pending, (state) => {
        state.downloadReportLoading = true;
      })
      .addCase(downloadReportThunkComplaint.fulfilled, (state, action) => {
        state.downloadReportLoading = false;
        state.downloadComplaintReport = action.payload;
        state.error = null;
      })
      .addCase(downloadReportThunkComplaint.rejected, (state, action) => {
        state.downloadReportLoading = false;
        state.downloadComplaintReport = "";
        state.error = action.error.message ?? "An error occurred";
      })

      .addCase(downloadReportThunkLead.pending, (state) => {
        state.downloadReportLoading = true;
      })
      .addCase(downloadReportThunkLead.fulfilled, (state, action) => {
        state.downloadReportLoading = false;
        state.downloadLeadReport = action.payload;
        state.error = null;
      })
      .addCase(downloadReportThunkLead.rejected, (state, action) => {
        state.downloadReportLoading = false;
        state.downloadLeadReport = "";
        state.error = action.error.message ?? "An error occurred";
      })

      .addCase(downloadReportThunkUser.pending, (state) => {
        state.downloadReportLoading = true;
      })
      .addCase(downloadReportThunkUser.fulfilled, (state, action) => {
        state.downloadReportLoading = false;
        state.downloadUserReport = action.payload;
        state.error = null;
      })
      .addCase(downloadReportThunkUser.rejected, (state, action) => {
        state.downloadReportLoading = false;
        state.downloadUserReport = "";
        state.error = action.error.message ?? "An error occurred";
      })

      .addCase(downloadReportThunkKyp.pending, (state) => {
        state.downloadReportLoading = true;
      })
      .addCase(downloadReportThunkKyp.fulfilled, (state, action) => {
        state.downloadReportLoading = false;
        state.downloadKypReport = action.payload;
        state.error = null;
      })
      .addCase(downloadReportThunkKyp.rejected, (state, action) => {
        state.downloadReportLoading = false;
        state.downloadKypReport = "";
        state.error = action.error.message ?? "An error occurred";
      })

      .addCase(downloadReportThunkKypConsolidated.pending, (state) => {
        state.downloadReportLoading = true;
      })
      .addCase(downloadReportThunkKypConsolidated.fulfilled, (state, action) => {
        state.downloadReportLoading = false;
        state.downloadKypConsolidatedReport = action.payload;
        state.error = null;
      })
      .addCase(downloadReportThunkKypConsolidated.rejected, (state, action) => {
        state.downloadReportLoading = false;
        state.downloadKypConsolidatedReport = "";
        state.error = action.error.message ?? "An error occurred";
      })

      .addCase(downloadReportThunkActiveUser.pending, (state) => {
        state.downloadReportLoading = true;
      })
      .addCase(downloadReportThunkActiveUser.fulfilled, (state, action) => {
        state.downloadReportLoading = false;
        state.downloadActiveUserReport = action.payload;
        state.error = null;
      })
      .addCase(downloadReportThunkActiveUser.rejected, (state, action) => {
        state.downloadReportLoading = false;
        state.downloadActiveUserReport = "";
        state.error = action.error.message ?? "An error occurred";
      })
      .addCase(reportDataThunkActiveUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(reportDataThunkActiveUser.fulfilled, (state, action) => {
        if (action.payload.data?.users?.length > 0) {
          toast.success("User Report Generated Successfully");
        }
        state.activeUserData = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(reportDataThunkActiveUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? "An error occurred";
      })

      .addCase(downloadReportThunkSettled.pending, (state) => {
        state.downloadReportLoading = true;
      })
      .addCase(downloadReportThunkSettled.fulfilled, (state, action) => {
        state.downloadReportLoading = false;
        state.downloadSettledCaseReport = action.payload;
        state.error = null;
      })
      .addCase(downloadReportThunkSettled.rejected, (state, action) => {
        state.downloadReportLoading = false;
        state.downloadSettledCaseReport = "";
        state.error = action.error.message ?? "An error occurred";
      });
  }
});

export default referralSlice.reducer;
