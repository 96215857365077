import React from "react";

interface TableProps {
  tableHeading: string[];
  tableKeys: string[];
  tableData: any[];
}

const SimpleTableDashboard: React.FC<TableProps> = ({ tableHeading, tableKeys, tableData }) => {
  return (
    <div
      className="overflow-x-scroll w-full mr-6"
      style={{
        scrollbarWidth: "thin",
        scrollbarColor: "#D7DBEC transparent",
        msOverflowStyle: "none",
        overflowX: "scroll",
        overflowY: "hidden"
      }}
    >
      <table className="w-full">
        <thead className="text-center">
          <tr className="border-b-2 bg-[#e2f5fe] whitespace-nowrap ">
            {tableHeading.map((data: any, i: number) => (
              <th key={i} className="pr-1 md:pr-3 py-2 md:py-4 text-sm md:text-base  ">
                {data}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="text-gray-600 w-full text-center">
          {tableData && tableData?.length > 0 ? (
            tableData.map((data, index) => (
              <tr key={index} className="border-b-2">
                {tableKeys.map((key, i) => {
                  if (i === 0) return null;

                  if (tableKeys[0] === "settledCase" && key === "serial") return <td>{index + 1}</td>;
                  if (tableKeys[0] === "settledCase" && key === "finalAmountToBePaid")
                    return (
                      <td className=" font-bold">
                        ₹ <span className="text-green-400">{data[key] || "-"}</span>
                      </td>
                    );

                  let cellValue = data[key];

                  if (key.includes(".")) {
                    const nestedKeys = key.split(".");
                    let nestedData = data;
                    for (const nestedKey of nestedKeys) {
                      nestedData = nestedData[nestedKey];
                      if ([undefined, null].includes(nestedData)) {
                        cellValue = "-";
                        break;
                      } else {
                        cellValue = nestedData;
                      }
                    }
                  }
                  return (
                    <td key={i} className="px-6 py-4 ">
                      {cellValue || "-"}
                    </td>
                  );
                })}
              </tr>
            ))
          ) : (
            <td colSpan={tableHeading?.length} className="text-center text-xl pt-2">
              No data found
            </td>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default SimpleTableDashboard;
