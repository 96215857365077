import React from "react";
import Typography from "../../../component/semantic/Typography";
import Partner1 from "../../../assets/icons/home/partner1.svg";
import Partner2 from "../../../assets/icons/home/partner2.svg";
import Partner3 from "../../../assets/icons/home/partner3.svg";
import Partner4 from "../../../assets/icons/home/partner4.svg";
import Partner5 from "../../../assets/icons/home/partner5.svg";
import Partner6 from "../../../assets/icons/home/partner6.svg";
import Partner7 from "../../../assets/icons/home/partner7.svg";
import Partner8 from "../../../assets/icons/home/partner8.svg";
import Partner9 from "../../../assets/icons/home/partner9.svg";
import Partner10 from "../../../assets/icons/home/partner10.svg";
import Partner11 from "../../../assets/icons/home/partner11.svg";
import Partner12 from "../../../assets/icons/home/partner12.svg";
import "./style.css";
import Carousel from "./Carousel";

function ExistingPartner() {
  const images = [Partner1, Partner2, Partner3, Partner4, Partner5, Partner6, Partner7, Partner8, Partner9, Partner10, Partner11, Partner12];

  const imagesArr = [
    <div key={"first"} className="grid grid-cols-4  gap-y-10">
      <img className="col-span-2 mx-auto shadow-sm p-2" alt="img" src={Partner1} />
      <img className="col-span-2 mx-auto shadow-sm p-2" alt="img" src={Partner2} />
      <img className="col-span-2 mx-auto shadow-sm p-2" alt="img" src={Partner7} />
      <img className="col-span-2 mx-auto shadow-sm p-2" alt="img" src={Partner6} />
    </div>,
    <div key={"second"} className="grid grid-cols-4  gap-y-10">
      <img className="col-span-2 mx-auto shadow-sm p-2" alt="img" src={Partner4} />
      <img className="col-span-2 mx-auto shadow-sm p-2" alt="img" src={Partner5} />
      <img className="col-span-2 mx-auto shadow-sm p-2" alt="img" src={Partner12} />
      <img className="col-span-2 mx-auto shadow-sm p-2" alt="img" src={Partner10} />
    </div>,
    <div key={"third"} className="grid grid-cols-4  gap-y-10">
      <img className="col-span-2 mx-auto shadow-sm p-2" alt="img" src={Partner3} />
      <img className="col-span-2 mx-auto shadow-sm p-2" alt="img" src={Partner9} />
      <img className="col-span-2 mx-auto shadow-sm p-2" alt="img" src={Partner8} />
      <img className="col-span-2 mx-auto shadow-sm p-2" alt="img" src={Partner11} />
    </div>
  ];

  return (
    <div className="bg-white pb-16 pt-20 relative md:mt-0">
      <div className="w-full text-center">
        <Typography className="text-4xl md:text-6xl" variant="h2">
          Existing partners
        </Typography>
      </div>

      <div className="flex md:hidden justify-center w-full mt-[10%]">
        <Carousel items={imagesArr} />
      </div>
      <div className="md:flex w-[85%] hidden justify-between mx-auto gap-x-20 flex-wrap mt-20 gap-y-8">
        {images?.map((res: string, index: number) => {
          return <img key={index} alt={"partner-image"} src={res} />;
        })}
      </div>
    </div>
  );
}

export default ExistingPartner;
