import React, { useEffect, useState } from "react";
import Container from "../../component/common/Container";
import ButtonOutline from "../../component/semantic/Button";
import { PERMISSIONS, complaintStatusConstant } from "../../helpers/constants/constants";
import Typography from "../../component/semantic/Typography";
import Spinner from "../../component/common/Spinner";
import { useAppDispatch, useAppSelector } from "../../hooks/redux_toolkit";
import ActionTable from "./component/ActionTable";
import Pagination from "../../component/common/Pagination";
import { getCurrentUser } from "../../helpers/currentUser";
import { complaintHeading, complaintKeys, sortKeysComplaint } from "../../component/table/TableConstants";
import emptyLead from "../../assets/icons/lead/emptyLead.svg";
import { getAllComplaintThunk, searchComplaintCustomerThunk, searchComplaintPartnerAdmin, searchComplaintPartnerThunk } from "../../store/thunk/AllComplaint.thunk";
import InputIcon from "../../component/semantic/InputIconMob";
import { IoIosArrowDown } from "react-icons/io";
import commentIcon from "../../assets/icons/allcomplaints/addComment.svg";
import contractIcon from "../../assets/icons/allcomplaints/contract.svg";
import docIcon from "../../assets/icons/allcomplaints/seeDocuments.svg";
import statusIcon from "../../assets/icons/allcomplaints/statusHistory.svg";
import invoiceIcon from "../../assets/icons/allcomplaints/viewInvoice.svg";
import UpdateDetailsIcon from "../../assets/icons/allcomplaints/multipleUpdate.svg";
import assignExpertIcon from "../../assets/icons/allcomplaints/assignExpert.svg";
import assignExecutiveIcon from "../../assets/icons/allcomplaints/assignExecutive.svg";
import StatusHistory from "./component/dialogs/StatusHistory";
import AddComment from "./component/dialogs/AddComment";
import ViewInvoice from "./component/dialogs/ViewInvoice";
import ViewDocument from "./component/dialogs/ViewDocument";
import ViewContract from "./component/dialogs/ViewContract";
import SendContract from "./component/dialogs/SendContract";
import ChangeStatus from "./component/dialogs/ChangeStatus";
import AssignLead from "./component/dialogs/AssignLead";
import AssignExpert from "./component/dialogs/AssignExpert";
import UpdateDetails from "./component/dialogs/UpdateDetails";
// import EditCase from "./component/dialogs/EditCase";
import AddComments from "./component/dialogs/AddComments";
import LeadInvoicing from "./component/dialogs/LeadInvoicing";
import sendContractIcon from "../../assets/icons/allcomplaints/sendContract.svg";
import editIcon from "../../assets/icons/allcomplaints/edit.svg";
import changeStatusIcon from "../../assets/icons/allcomplaints/changeStatus.svg";
import generateInvoice from "../../assets/icons/allcomplaints/generateInvoice.svg";
import { setSortedDataComplaint } from "../../store/slices/AllComplaintSlice";
import UserAccount from "./component/dialogs/UserAccount";
import InputIconClear from "./component/InputIcon";
import checkAuthorizationService from "../../services/CheckAuthorization.service";

const AllComplaints = () => {
  const [selectedStatus, setSelectedStatus] = useState<any>({ label: "All", value: "All" });
  const [subModulesOfSelected, setSubModulesOfSelected] = useState<any>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [partnerSearchInput, setPartnerSearchInput] = useState("");
  const [customerSearchInput, setCustomerSearchInput] = useState("");
  const [showStatus, setShowStatus] = useState(false);
  const [selectedComplaint, setSelectedComplaint] = useState<any>("");
  const [commentModal, setCommentModal] = useState(false);
  const [invoiceModal, setInvoiceModal] = useState(false);
  const [statusModal, setStatusModal] = useState(false);
  const [contractModal, setContractModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [changeStatus, setChangeStatus] = useState(false);
  const [assignLead, setAssignLead] = useState(false);
  const [assignExpert, setAssignExpert] = useState(false);
  const [updateDetails, setUpdateDetails] = useState(false);
  // eslint-disable-next-line
  const [editCase, setEditCase] = useState(false);
  const [addComments, setAddComments] = useState(false);
  const [leadInvoicing, setLeadInvoicing] = useState(false);
  const [docModal, setDocModal] = useState(false);
  const [userAccountModal, setUserAccountModal] = useState(false);

  const pageSize = 10;
  const dispatch = useAppDispatch();
  const currentUser = getCurrentUser();

  const updateStatusHandler = (data: any) => {
    setSelectedStatus(data);
    if (data && data.subModules && data.subModules?.length > 0) {
      setSubModulesOfSelected(data.subModules[0]);
    } else {
      setSubModulesOfSelected(null);
    }
  };

  const complaintData = useAppSelector((state) => state.rootReducer.allComplaint);
  const loginData = useAppSelector((state) => state.rootReducer.login);

  useEffect(() => {
    if (partnerSearchInput) {
      if (currentUser?.userType == "Manager") dispatch(searchComplaintPartnerAdmin({ search: partnerSearchInput, currentPage, pageSize }));
      else dispatch(searchComplaintPartnerThunk({ search: partnerSearchInput, page: currentPage, size: pageSize, hierarchy: currentUser?.hierarchicalLevel }));
    } else if (customerSearchInput) {
      dispatch(searchComplaintCustomerThunk({ search: customerSearchInput, page: currentPage, size: pageSize, userAccount: currentUser._id, userType: currentUser?.userType }));
    } else {
      if (selectedStatus.value === "All") dispatch(getAllComplaintThunk({ id: currentUser._id, pageSize, currentPage }));
      else dispatch(getAllComplaintThunk({ id: currentUser._id, pageSize, currentPage, status: subModulesOfSelected?.value || selectedStatus.value }));
    }
  }, [currentPage, selectedStatus, subModulesOfSelected]);

  const searchHandlerCustomer = () => {
    if (customerSearchInput) dispatch(searchComplaintCustomerThunk({ search: customerSearchInput ? customerSearchInput.trim() : "", page: 1, size: pageSize, userAccount: currentUser._id, userType: currentUser?.userType }));
    else dispatch(getAllComplaintThunk({ id: currentUser._id, pageSize, currentPage }));
    setPartnerSearchInput("");
  };

  const searchHandlerPartner = () => {
    if (partnerSearchInput) {
      if (currentUser?.userType == "Manager") dispatch(searchComplaintPartnerAdmin({ search: partnerSearchInput ? partnerSearchInput.trim() : "", currentPage: 1, pageSize }));
      else dispatch(searchComplaintPartnerThunk({ search: partnerSearchInput, page: 1, size: pageSize, hierarchy: currentUser?.hierarchicalLevel }));
    } else dispatch(getAllComplaintThunk({ id: currentUser._id, pageSize, currentPage }));
    setCustomerSearchInput("");
  };

  const clearFilterHandler = () => {
    setCustomerSearchInput("");
    setPartnerSearchInput("");
    if (selectedStatus.value === "All") dispatch(getAllComplaintThunk({ id: currentUser._id, pageSize, currentPage: 1 }));
    else dispatch(getAllComplaintThunk({ id: currentUser._id, pageSize, currentPage: 1, status: subModulesOfSelected?.value || selectedStatus.value }));
  };

  const actions: any = [
    { image: <img src={docIcon} className="cursor-pointer" />, isAuthorizedRoute: checkAuthorizationService(loginData.roles, [PERMISSIONS.ADMIN, PERMISSIONS.PARTNER, PERMISSIONS.ASSOCIATE, PERMISSIONS.ADVISOR]), tooltip: "View Document", onClickFunction: () => setDocModal(true) },
    { image: <img src={commentIcon} className="cursor-pointer" />, isAuthorizedRoute: checkAuthorizationService(loginData.roles, [PERMISSIONS.ADMIN]), tooltip: "Add Comment", onClickFunction: () => setAddComments(true) },
    { image: <img src={contractIcon} className="cursor-pointer" />, isAuthorizedRoute: checkAuthorizationService(loginData.roles, [PERMISSIONS.PARTNER, PERMISSIONS.ASSOCIATE, PERMISSIONS.ADVISOR]), tooltip: "Add Comment", onClickFunction: () => setCommentModal(true) },
    { image: <img src={statusIcon} className="cursor-pointer" />, isAuthorizedRoute: checkAuthorizationService(loginData.roles, [PERMISSIONS.ADMIN, PERMISSIONS.PARTNER, PERMISSIONS.ASSOCIATE, PERMISSIONS.ADVISOR]), tooltip: "Status History", onClickFunction: () => setStatusModal(true) },
    { image: <img src={invoiceIcon} className="cursor-pointer" />, isAuthorizedRoute: checkAuthorizationService(loginData.roles, [PERMISSIONS.ADMIN, PERMISSIONS.PARTNER, PERMISSIONS.ASSOCIATE, PERMISSIONS.ADVISOR]), tooltip: "View Invoice", onClickFunction: () => setInvoiceModal(true) },
    { image: <img src={contractIcon} className="cursor-pointer" />, isAuthorizedRoute: checkAuthorizationService(loginData.roles, [PERMISSIONS.ADMIN, PERMISSIONS.PARTNER, PERMISSIONS.ASSOCIATE, PERMISSIONS.ADVISOR]), tooltip: "View End User Contract", onClickFunction: () => setContractModal(true) },
    { image: <img src={sendContractIcon} className="cursor-pointer" />, isAuthorizedRoute: checkAuthorizationService(loginData.roles, [PERMISSIONS.ADMIN]), tooltip: "Send Contract Email", onClickFunction: () => setConfirmationModal(true) },
    { image: <img src={changeStatusIcon} className="cursor-pointer" />, isAuthorizedRoute: checkAuthorizationService(loginData.roles, [PERMISSIONS.ADMIN]), tooltip: "Change Case Status", onClickFunction: () => setChangeStatus(true) },
    { image: <img src={editIcon} className="cursor-pointer" />, isAuthorizedRoute: checkAuthorizationService(loginData.roles, [PERMISSIONS.ADMIN]), tooltip: "Edit Case", onClickFunction: () => setEditCase(true) },
    { image: <img src={generateInvoice} className="cursor-pointer" />, isAuthorizedRoute: checkAuthorizationService(loginData.roles, [PERMISSIONS.ADMIN]), tooltip: "Generate Invoice for Lead", onClickFunction: () => setLeadInvoicing(true) },
    { image: <img src={assignExecutiveIcon} className="cursor-pointer" />, isAuthorizedRoute: checkAuthorizationService(loginData.roles, [PERMISSIONS.ADMIN]), tooltip: "Assign lead to", onClickFunction: () => setAssignLead(true) },
    { image: <img src={assignExpertIcon} className="cursor-pointer" />, isAuthorizedRoute: checkAuthorizationService(loginData.roles, [PERMISSIONS.ADMIN]), tooltip: "Assign Expert", onClickFunction: () => setAssignExpert(true) },
    { image: <img src={UpdateDetailsIcon} className="cursor-pointer" />, isAuthorizedRoute: checkAuthorizationService(loginData.roles, [PERMISSIONS.ADMIN]), tooltip: "Update Details", onClickFunction: () => setUpdateDetails(true) }
    // { image: <img src={userAccountIcon} className="cursor-pointer" />, isAuthorizedRoute: checkAuthorizationService(loginData.roles, [PERMISSIONS.ADMIN]), tooltip: "User Account", onClickFunction: () => setUserAccountModal(true) }
  ];

  return (
    <>
      <Container>
        <form className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8 w-full">
          <div className="hidden md:flex  justify-center items-end gap-2 w-full">
            <InputIconClear onKeyDown={(e) => e.key == "Enter" && searchHandlerCustomer()} value={customerSearchInput} label="Search for Customer" placeholder="Name/Mobile/Email" onChange={(e) => setCustomerSearchInput(e.target.value)} onClickFunction={clearFilterHandler} />
            <ButtonOutline type="button" className={`bg-polyfixTheme text-white w-full md:w-42 `} title="Search" onClick={searchHandlerCustomer} />
          </div>
          <div className="hidden md:flex  justify-center items-end gap-2 w-full">
            <InputIconClear onKeyDown={(e) => e.key == "Enter" && searchHandlerPartner()} value={partnerSearchInput} label="Search for Agent, Associate, Partner" placeholder="Name/Mobile/Email" onChange={(e) => setPartnerSearchInput(e.target.value)} onClickFunction={clearFilterHandler} />
            <ButtonOutline type="button" className={`bg-polyfixTheme text-white w-full md:w-42`} title="Search" onClick={searchHandlerPartner} />
          </div>
          <div className="flex md:hidden w-full flex-col gap-1 ">
            <label className="font-semibold">Search for Customer</label>
            <InputIcon placeholder="Name/Mobile/Email" onClickFunction={() => searchHandlerCustomer()} value={customerSearchInput} onChange={(e) => setCustomerSearchInput(e.target.value)} />
          </div>
          <div className="flex md:hidden w-full flex-col gap-1 ">
            <label className="font-semibold">Search for Agent, Associate, Partner</label>
            <InputIcon placeholder="Name/Mobile/Email" onClickFunction={() => searchHandlerPartner()} value={partnerSearchInput} onChange={(e) => setPartnerSearchInput(e.target.value)} />
          </div>
        </form>
      </Container>
      <Container customClassName="mt-6">
        <div className={`flex gap-2 transition-all flex-wrap items-center ${showStatus ? "h-full pb-10 md:pb-10" : "h-28 md:h-full"}  md:h-full overflow-hidden relative`}>
          {/* {complaintStatusConstant.map((status: any, i: number) => (
            <p onClick={() => updateStatusHandler(status)} className={`border-2 px-5 py-2  cursor-pointer font-semibold text-center rounded-full ${selectedStatus?.value === status.value && "text-[#086F3D] bg-[#cbf2df]"}`} key={i}>
              {status.label}
            </p>
          ))} */}
          {complaintStatusConstant.map((status: any, i: number) => {
            if (status.label == "Pending") {
              if (checkAuthorizationService(loginData.roles, [PERMISSIONS.ADMIN])) {
                return (
                  <p onClick={() => updateStatusHandler(status)} className={`border-2 px-5 py-2  cursor-pointer font-semibold text-center rounded-full ${selectedStatus?.value === status.value && "text-[#086F3D] bg-[#cbf2df]"}`} key={i}>
                    {status.label}
                  </p>
                );
              }
            } else {
              return (
                <p onClick={() => updateStatusHandler(status)} className={`border-2 px-5 py-2  cursor-pointer font-semibold text-center rounded-full ${selectedStatus?.value === status.value && "text-[#086F3D] bg-[#cbf2df]"}`} key={i}>
                  {status.label}
                </p>
              );
            }
          })}
          <div onClick={() => setShowStatus(!showStatus)} className="absolute w-36 justify-center cursor-pointer md:hidden bottom-[-0.5rem] shadow-all left-1/2 transform -translate-x-1/2  border border-polyfixTheme flex items-center p-3 bg-[#fff9f3] rounded-md gap-2">
            <Typography className="text-polyfixTheme">View {showStatus ? "Less" : "More"}</Typography>
            <IoIosArrowDown className={`text-gray-400 transition-all text-lg ${showStatus ? "rotate-180" : "rotate-0"}`} />
          </div>
        </div>
        {selectedStatus && selectedStatus?.subModules && selectedStatus?.subModules?.length > 0 && (
          <>
            <hr className="mt-8" />
            <div className="mt-8 mb-2 flex item-center gap-2">
              <div className="w-[24px] h-[20px] bg-[#8FCAAD] border-[5px] border-[#CBF2DF]"></div>
              <Typography variant="h1" className="text-gray-600 mb-6">
                {selectedStatus.label} Buckets Section
              </Typography>
            </div>
            <div className="flex gap-2 flex-wrap items-center">
              {selectedStatus?.subModules.map((status: any, i: number) => (
                <p onClick={() => setSubModulesOfSelected(status)} className={`border-2 px-5 py-2  cursor-pointer font-semibold text-center rounded-full ${subModulesOfSelected?.value === status.value && "text-[#086F3D] bg-[#cbf2df]"}`} key={i}>
                  {status.label}
                </p>
              ))}
            </div>
          </>
        )}
      </Container>
      <Container customClassName="mt-6 ">
        <Typography variant="h1">All Cases</Typography>
        <Typography className="text-gray-600 mb-6">List of all cases provided by you and their status </Typography>
        {complaintData.loading ? (
          <Spinner />
        ) : complaintData.complaintData.data && complaintData.complaintData.data.data?.length == 0 ? (
          <div className="h-72 flex flex-col justify-center gap-3 items-center">
            <img src={emptyLead} />
            <Typography variant="h3" className="text-[#666666]">
              No data available for this status
            </Typography>
          </div>
        ) : (
          <>
            <ActionTable actions={actions} selectedRow={setSelectedComplaint} tableHeading={complaintHeading} tableKeys={complaintKeys} tableData={complaintData.complaintData?.data?.data || []} sortKey={sortKeysComplaint} sortedDataAction={setSortedDataComplaint} />
            <Pagination itemsPerPage={pageSize} totalItems={complaintData.complaintData?.data?.count || 0} setCurrentPage={setCurrentPage} currentPage={currentPage} />
          </>
        )}
        <StatusHistory selectedComplaint={selectedComplaint} setShowStatusHistory={setStatusModal} showStatusHistory={statusModal} />
        <AddComment showAddCommentModal={commentModal} setShowAddCommentModal={setCommentModal} selectedComplaint={selectedComplaint} />
        <ViewInvoice selectedComplaint={selectedComplaint} setInvoice={setInvoiceModal} viewInvoice={invoiceModal} />
        <ViewDocument docModal={docModal} setDocModal={setDocModal} selectedComplaint={selectedComplaint} />
        <ViewContract selectedComplaint={selectedComplaint} setContractModal={setContractModal} viewContract={contractModal} />
        <SendContract leadData={selectedComplaint?.leadId} confirmationModal={confirmationModal} setConfirmationModal={setConfirmationModal} />
        <ChangeStatus selectedComplaint={selectedComplaint} changeStatus={changeStatus} setChangeStatus={setChangeStatus} />
        <AssignLead leadData={selectedComplaint} showModal={assignLead} setShowLeadModal={setAssignLead} />
        <AssignExpert leadData={selectedComplaint} showModal={assignExpert} setShowLeadModal={setAssignExpert} />
        <UpdateDetails selectedComplaint={selectedComplaint} updateDetails={updateDetails} setUpdateDetails={setUpdateDetails} />
        {/* <EditCase selectedComplaint={selectedComplaint} editCase={editCase} setEditCase={setEditCase} /> */}
        {addComments && <AddComments selectedComplaint={selectedComplaint} addComments={addComments} setAddComments={setAddComments} />} <UserAccount selectedComplaint={selectedComplaint} viewUserAccount={userAccountModal} setViewUserAccount={setUserAccountModal} />
        <LeadInvoicing selectedComplaint={selectedComplaint} leadInvoicing={leadInvoicing} setLeadInvoicing={setLeadInvoicing} />
      </Container>
    </>
  );
};

export default AllComplaints;
