import React, { FC, useEffect, useState } from "react";
import Modal from "../../../../component/common/Modal";
import Typography from "../../../../component/semantic/Typography";
import CloseIcon from "../../../../assets/icons/gallery/close.svg";
import ButtonOutline from "../../../../component/semantic/Button";
import addCommentImage from "../../../../assets/icons/lead/addComment.svg";
import CustomInput from "../../../../component/semantic/TextArea";
import dayjs from "dayjs";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux_toolkit";
import { toast } from "react-toastify";
import { listOfExperts } from "../../../../helpers/config";
import { addLeadCommentThunk, leadCommentHistoryThunk } from "../../../../store/thunk/AllLead.thunk";

interface ModalProps {
  addComments: boolean;
  setAddComments: any;
  selectedLead: any;
}

const AddCommentsAdmin: FC<ModalProps> = ({ setAddComments, addComments, selectedLead }) => {
  const [commentInput, setCommentInput] = useState("");
  const [isPrivate, setPrivate] = useState(false);
  const [selectedExperts, setSelectedExperts] = useState<{ name: string; value: string }[]>([]);

  const dispatch = useAppDispatch();
  const addCommentHandler = async () => {
    if (commentInput.trim()) {
      await dispatch(addLeadCommentThunk({ id: selectedLead._id, body: { comment: commentInput, notificationsTo: selectedExperts, private: isPrivate, type: "Complaint" } }))
        .unwrap()
        .then((data: any) => {
          console.log(data);
          if (data?.data?.success) {
            toast.success(data?.data?.message);
            dispatch(leadCommentHistoryThunk(selectedLead?._id));
            setSelectedExperts([]);
            setPrivate(false);
            setCommentInput("");
            // setShowAddCommentModal(false);
          } else {
            // toast.error(data.message);
          }
        });
    }
  };

  useEffect(() => {
    if (selectedLead?._id) dispatch(leadCommentHistoryThunk(selectedLead?._id));
    setSelectedExperts([]);
    setPrivate(false);
  }, [selectedLead]);

  const commentHistoryData = useAppSelector((state) => state.rootReducer.allLead.commentHistory.data);
  console.log(commentHistoryData);

  return (
    <Modal showModal={addComments} onClose={() => setAddComments(false)} className="relative w-[35%] p-8">
      <img src={CloseIcon} className="absolute text-3xl text-gray-400 right-[-15px] top-[-20px] cursor-pointer" onClick={() => setAddComments(false)} />
      <Typography className="text-2xl font-semibold">Add Comment</Typography>
      <div className="mt-8">
        <Typography className="font-extrabold">Select the experts to send notifications of this comment</Typography>
      </div>
      <div className="h-[60vh] overflow-scroll">
        <div className="mt-2">
          {listOfExperts.map((i: any, key: number) => {
            return (
              <div className="inline-flex items-center" key={key}>
                <label className="relative flex items-center p-3 rounded-full cursor-pointer" htmlFor="check">
                  <input
                    type="checkbox"
                    onChange={(e: any) => {
                      if (e.target.checked) {
                        setSelectedExperts((prev) => [...prev, i.value]);
                      } else {
                        let temp = [...selectedExperts];
                        temp = temp.filter((obj) => obj.value !== i);
                        setSelectedExperts(temp);
                      }
                    }}
                    className="w-4 h-4 text-black bg-gray-100 border-gray-300 rounded focus:ring-gray-200 dark:focus:ring-gray-200 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                </label>
                <label className="mt-px font-light text-gray-700 cursor-pointer select-none" htmlFor="check">
                  {i.name}
                </label>
              </div>
            );
          })}
        </div>
        <div className="mt-4">Is this message is private for internal purposes?</div>
        <div className="mt-2">
          <div className="flex gap-10">
            <div className="inline-flex gap-2 items-center">
              <input id="default-radio-1" type="radio" onChange={() => setPrivate(true)} name="default-radio" className="w-4 h-4" />
              <label className="mt-px font-light text-gray-700 cursor-pointer select-none" htmlFor="html">
                Yes
              </label>
            </div>
            <div className="inline-flex gap-2 items-center">
              <input id="default-radio-1" type="radio" defaultChecked onChange={() => setPrivate(false)} name="default-radio" className="w-4 h-4" />
              <label className="mt-px font-light text-gray-700 cursor-pointer select-none" htmlFor="react">
                No
              </label>
            </div>
          </div>
        </div>
        <div className="mt-2">
          <div className="flex items-center justify-between">
            <Typography>Comments</Typography>
            <ButtonOutline onClick={addCommentHandler} className="p-2 whitespace-nowrap bg-[#FAAA52]" title="Add Comment" />
          </div>
          <CustomInput value={commentInput} onChange={(e: any) => setCommentInput(e.target.value)} className="!h-[20vh] mt-2" placeholder="Add your comments here..." />
        </div>
        <div className="mt-2">
          <Typography variant="h2">Comment history for Lead ID: {selectedLead?.leadId}</Typography>

          <div className="custom-polifyx-scrollbar overflow-y-scroll h-[20vh] px-4">
            {commentHistoryData &&
              commentHistoryData.map((comment: any, i: number) => {
                const nameAndDate = comment.name + " | " + dayjs(comment.createdAt).format("ddd, MMM D, YYYY, h:mm A");
                return (
                  <div key={i} className="my-6 relative shadow-all px-6 py-4 flex gap-4 items-start rounded-xl">
                    <img src={addCommentImage} className="mt-1" />
                    <div className="flex gap-1 flex-col">
                      <Typography variant="h2">{comment?.comment}</Typography>
                      <Typography className="text-xs text-gray-400">{nameAndDate}</Typography>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddCommentsAdmin;
