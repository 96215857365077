import React, { useEffect, useState } from "react";
import Typography from "../../../component/semantic/Typography";
import FileUpload from "../../../component/common/FileUpload";
import Select from "react-select";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux_toolkit";
import { Option, MultiValueLabel } from "../../dashboard/components/ReactSelectCustom";
import { allStates } from "../../../helpers/constants/constants";
import { agentDocThunk, profileDocDelete, userUpdate } from "../../../store/thunk/Profile.thunk";
import { FaFile } from "react-icons/fa";
import { polyfixSelect, customMultiValue, errorStyles } from "../../../helpers/constants/styles";
import FormInput from "../../../component/semantic/FormInput";
import { professionalDetails } from "../../../helpers/initialValues";
import { useFormik } from "formik";
import ButtonOutline from "../../../component/semantic/Button";
import { isEmpty } from "../../../helpers/utils";
import { professionalDetailsSchema } from "../../../helpers/schemas/Profile.Schema";
import { showToast } from "../../../store/slices/Common.slice";
import { ImSpinner2 } from "react-icons/im";
import { getCurrentUser } from "../../../helpers/currentUser";
import { userProfile } from "../../../store/thunk/Common.thunk";
import deleteDoc from "../../../assets/icons/newCase/deleteDoc.svg";
import { toast } from "react-toastify";

const ProfessionalDetails = () => {
  const dispatch = useAppDispatch();

  const profileData: any = useAppSelector((state) => state.rootReducer.common.userProfile?.data);
  const loading = useAppSelector((state) => state.rootReducer.profile.userLoading);

  const [formValues, setFormValue] = useState<any>(professionalDetails);
  const currentUser = getCurrentUser();

  useEffect(() => {
    if (profileData) {
      setFormValue({
        companyName: profileData?.companyName,
        areaOfOperation: profileData?.areaOfOperation,
        natureOfBusiness: profileData?.natureOfBusiness,
        agentCodeDoc: profileData?.agentCodeDoc,
        yearsOfExperience: profileData?.yearsOfExperience,
        otherCompanyName: profileData?.otherCompanyName,
        panNumber: profileData?.panNumber,
        agentCode: profileData?.agentCode,
        userType: profileData?.userType,
        createdAt: profileData?.createdAt
      });
    }
  }, [profileData]);

  const { values, handleSubmit, handleBlur, handleChange, touched, errors, setFieldError, setFieldValue } = useFormik({
    initialValues: formValues,
    validationSchema: professionalDetailsSchema,
    enableReinitialize: true,
    onSubmit: async (values: any) => {
      if (!values.natureOfBusiness) setFieldError("natureOfBusiness", "Nature of Business is required!!");

      values = {
        ...values,
        companyName: values.companyName.includes("Other") ? values.companyName : values.companyName.map((company: any) => company?.name),
        natureOfBusiness: values.natureOfBusiness ? values.natureOfBusiness.name : ""
      };

      const updateNotification = await dispatch(userUpdate(values)).unwrap();

      if (updateNotification.status == 200) dispatch(showToast({ message: "Profile Updated Successfully", method: "success" }));
    }
  });

  const [selectedFile, setSelectedFile] = useState<any>(profileData?.agentCodeDoc);
  const [selectedState, setSelectedState] = useState<any>(values.areaOfOperation && values.areaOfOperation.map((obj: any) => ({ label: obj, value: obj })));
  const [selectedCompany, setSelectedCompany] = useState<any>(values?.companyName && (Array.isArray(profileData.companyName) ? values.companyName.map((obj: any) => ({ name: obj })) : [{ name: profileData.companyName }]));
  const [selectedBuisness, setSelectedBuisness] = useState<any>(values.natureOfBusiness && { label: values.natureOfBusiness, value: values.natureOfBusiness });

  const companyList = useAppSelector((state) => state.rootReducer.common.companyList);
  const natureOfBuisnessList = useAppSelector((state) => state.rootReducer.profile.natureOfBuisness?.data);

  useEffect(() => {
    if (profileData?.areaOfOperation) setSelectedState(profileData.areaOfOperation && profileData.areaOfOperation.map((obj: any) => ({ label: obj, value: obj })));
    if (profileData?.companyName) {
      if (Array.isArray(profileData.companyName)) {
        setSelectedCompany(profileData.companyName.map((obj: any) => ({ name: obj })));
      } else {
        setSelectedCompany([{ name: profileData.companyName }]);
      }
    }

    if (profileData?.natureOfBusiness) setSelectedBuisness(profileData.natureOfBusiness && { name: profileData.natureOfBusiness });
  }, [profileData]);

  useEffect(() => {
    if (selectedFile && selectedFile !== profileData?.agentCodeDoc) {
      const formData = new FormData();
      formData.append("image", selectedFile);
      dispatch(agentDocThunk(formData))
        .unwrap()
        .then(() => {
          dispatch(userProfile(currentUser?._id));
        });
    }
  }, [selectedFile]);

  const allStateList = allStates.map((state) => ({ label: state, value: state }));

  const formProps = { values, handleBlur, handleChange, touched, errors, setFieldValue };

  const s3url = "https://insa-partners-stage.s3.ap-south-1.amazonaws.com/uploads/" + currentUser?._id;

  const deleteHandler = () => {
    dispatch(profileDocDelete({ userId: profileData?._id, file: "agentCodeDoc" }))
      .unwrap()
      .then((data: any) => {
        if (data?.success) {
          toast.success(data?.message);
          dispatch(userProfile(currentUser?._id));
          setSelectedFile(null);
        }
      });
  };

  return (
    <>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        <span>
          {/* <Select
            options={natureOfBuisnessArray && natureOfBuisnessArray}
            styles={polyfixSelect}
            placeholder="Enter Nature of Buisness"
            onChange={(option: any) => {
              formProps.setFieldValue("natureOfBusiness", option.label);
              setSelectedBuisness(option);
            }}
            isDisabled={values.natureOfBusiness ? true : false}
            value={selectedBuisness}
          /> */}
          <Typography className="font-normal mb-1">Nature of business *</Typography>

          <Select
            placeholder="Select Nature of business"
            styles={errors.natureOfBusiness && touched["natureOfBusiness"] ? errorStyles : { ...polyfixSelect, ...customMultiValue }}
            options={natureOfBuisnessList.map((obj: any) => {
              return { name: obj };
            })}
            onChange={(selectedOptions: any) => {
              setSelectedBuisness(selectedOptions);
              setFieldValue("natureOfBusiness", selectedOptions);
            }}
            value={selectedBuisness}
            getOptionValue={(option: any) => option?.name}
            getOptionLabel={(option: any) => option?.name}
          />
        </span>
        <span>
          <Typography className="font-normal">Area of Operations</Typography>
          <Select
            options={[{ label: "Pan India", value: "Pan India" }, ...allStateList]}
            isSearchable={false}
            isMulti
            name="areaOfOperation"
            closeMenuOnSelect={false}
            placeholder="Select Area of Operations"
            value={selectedState}
            styles={{ ...polyfixSelect, ...customMultiValue }}
            hideSelectedOptions={false}
            components={{
              Option: (props) => <Option {...props} checkColor="white" />,
              MultiValueLabel
            }}
            onChange={(option: any) => {
              formProps.setFieldValue(
                "areaOfOperation",
                option.map((obj: any) => obj.label)
              );
              if (option.find((obj: any) => obj.label === "Pan India")) {
                setSelectedState({ label: "Pan India", value: "Pan India" });
                return;
              }
              setSelectedState(option);
            }}
          />
        </span>
        <FormInput name="yearsOfExperience" type="number" limit={3} formProps={formProps} label="Years of experience in the industry *" placeholder="Enter Your Experience" />
        <span>
          <Typography className="font-normal">Company Name *</Typography>
          <Select
            options={companyList && [{ name: "Other" }, ...companyList.data]}
            getOptionLabel={(option: any) => option.name}
            getOptionValue={(option: any) => option.name}
            isSearchable={false}
            placeholder="Select Company Name"
            value={selectedCompany}
            hideSelectedOptions={false}
            styles={{ ...polyfixSelect, ...customMultiValue }}
            isMulti
            name="companyName"
            closeMenuOnSelect={false}
            onBlur={formProps.handleBlur}
            components={{
              Option: (props) => <Option {...props} checkColor="white" />,
              MultiValueLabel
            }}
            onChange={(selectedOptions: any) => {
              formProps.setFieldValue("companyName", selectedOptions);
              const otherSelected = selectedOptions.some((option: any) => option.name === "Other");
              if (otherSelected) {
                formProps.setFieldValue("companyName", ["Other"]);
                setSelectedCompany([{ name: "Other" }]);
              } else {
                formProps.setFieldValue("otherCompanyName", "");
                setSelectedCompany(selectedOptions);
              }
            }}
          />
          {formProps.values.companyName?.length == 0 && formProps.touched["react-select-6-input"] && <span className="mt-1 text-red-600 font-semibold text-sm">Please Select Company</span>}
        </span>
        {selectedCompany?.length > 0 && selectedCompany[0].name == "Other" && <FormInput name="otherCompanyName" formProps={formProps} label="Other Company Name*" placeholder="Enter other company name" />}
        <FormInput name="panNumber" limit={10} formProps={formProps} label="PAN Card *" placeholder="Enter pan number" />
        <FormInput name="agentCode" formProps={formProps} label="Agent Code/License Number" placeholder="Enter Agent Code/License Number" />
        <span className="">
          <Typography>Agent/License Document Upload</Typography>
          <FileUpload setSelectedFile={setSelectedFile} />
        </span>
        <span className="items-center flex">
          {selectedFile && (
            <>
              <div onClick={() => window.open(s3url + "/" + values.agentCodeDoc)} className="text-gray-700 bg-uploadTheme cursor-pointer flex px-4 rounded-lg py-1 gap-1 items-center">
                <FaFile className="text-gray-400 opacity-50 " />
                {selectedFile === values.agentCodeDoc ? values.agentCodeDoc : selectedFile.name}
              </div>
              <img src={deleteDoc} onClick={deleteHandler} className="ml-2 cursor-pointer" />
            </>
          )}
        </span>
      </div>
      <div className="md:flex block justify-stretch md:flex-row-reverse py-4 mt-6 border-t-2 ">
        <ButtonOutline icon={loading ? <ImSpinner2 className="animate-spin ml-2 -mt-1" /> : ""} onClick={handleSubmit} className={`bg-polyfixTheme  w-full md:w-32 ${!isEmpty(errors) && "opacity-50 pointer-events-none"}`} title="SAVE" type="submit" />
      </div>
    </>
  );
};

export default ProfessionalDetails;
