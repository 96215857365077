import React, { useEffect, useState } from "react";
import InputWithIcon from "../../../component/semantic/InputWithIcon";
import { IoSearch } from "react-icons/io5";
import { IoIosArrowBack } from "react-icons/io";
import Tabpane from "../../../component/common/Tabpane";
import DisplayPicCard from "./DisplayPicCard";
import Typography from "../../../component/semantic/Typography";
import profileDefault from "../../../assets/icons/navbar/profilePicDefault.jpg";
import dayjs from "dayjs";
import { s3Url } from "../../../helpers/constants/defaultUrls";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux_toolkit";
import { archiveChatThunk, deleteChatThunk, getAllContactsThunk, getLastMsgThunk, getUserChatThunk, pinUserChatThunk, searchContactsThunk, searchLastMsgThunk } from "../../../store/thunk/Chat.thunk";
import Divide from "../../../component/semantic/Divide";
import archive from "../../../assets/icons/chat/archive.svg";
import Circle from "../../../component/common/Circle";
import { getCurrentUser } from "../../../helpers/currentUser";
import Menu from "../../../component/common/Menu";
import deleteChat from "../../../assets/icons/chat/deleteChat.svg";
import pinChat from "../../../assets/icons/chat/pin.svg";
import { BsThreeDots } from "react-icons/bs";
import { FaImage, FaFile } from "react-icons/fa6";
import ChatSkeleton from "./ChatSkeleton";
import DeleteChat from "./Dialogs/DeleteChat";
import { chatLoader } from "../../../store/slices/Chat.slice";

interface ChatListProps {
  contactList: any;
  lastChatList: any;
  setSelectedChat: any;
  selectedChat: any;
  showMobChat: boolean;
  setMobChat: any;
  loading: boolean;
}

const ChatList: React.FC<ChatListProps> = ({ contactList, lastChatList, setSelectedChat, selectedChat, showMobChat, setMobChat, loading }) => {
  const [selectedTab, setSelectedTab] = useState(1);
  const [showArchive, setShowArchive] = useState(false);
  const [showActionMenu, setShowActionMenu] = useState<any>("");
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [lastChatListArr, setLastChatListArr] = useState<any>([]);
  const [archivedChatList, setArchivedChatList] = useState<any>([]);
  const [selectedRow, setSelectedRow] = useState<any>("");

  const dispatch = useAppDispatch();

  const currentUser = getCurrentUser();

  useEffect(() => {
    if (lastChatListArr) {
      const temp: any = [];
      lastChatListArr.map((obj: any) => {
        if (obj.userData[0]?.chatAction && obj.userData[0]?.chatAction?.archiveBy?.includes(currentUser._id)) temp.push(obj);
      });
      setArchivedChatList(temp);
    }
  }, [lastChatListArr]);

  if (archivedChatList?.length == 0 && showArchive) setShowArchive(false);

  console.log(selectedChat);

  const searchHandler = (e: any) => {
    // eslint-disable-next-line
    let debouncer;
    clearTimeout(debouncer);
    debouncer = setTimeout(() => {
      if (selectedTab == 1) dispatch(searchLastMsgThunk(e.target.value));
      else if (selectedTab == 2 && e.target.value) {
        dispatch(searchContactsThunk(e.target.value));
      } else dispatch(getAllContactsThunk());
    }, 500);
  };

  const onlineUserList = useAppSelector((state) => state.rootReducer.common.allOnlinceUserList);

  const actionMenuHandler = (obj: any) => {
    if (showActionMenu == obj._id) {
      setShowActionMenu("");
    } else setShowActionMenu(obj._id);
  };

  const chatHandler = (obj: any) => {
    if (showActionMenu) setShowActionMenu("");
    dispatch(chatLoader(true));
    dispatch(getUserChatThunk({ receiverId: obj._id, senderId: currentUser._id }));
    setSelectedChat(obj?.userData[0]);
    setMobChat(true);
  };

  const chatHandlerContact = (obj: any) => {
    dispatch(chatLoader(true));
    dispatch(getUserChatThunk({ receiverId: obj._id, senderId: currentUser._id }));
    setSelectedChat(obj);
    setMobChat(true);
  };

  useEffect(() => {
    if (lastChatList && onlineUserList) {
      const onlineUserIds = new Set(onlineUserList.map((user: any) => user.userId));

      const updatedChatList = lastChatList.map((chat: any) => ({
        ...chat,
        isOnline: onlineUserIds.has(chat._id)
      }));

      setLastChatListArr(updatedChatList);
    }
  }, [lastChatList, onlineUserList]);

  const pinChatHandler = async (payload: { senderId: string; receiverId: string; toPin: boolean }) => {
    await dispatch(pinUserChatThunk(payload));
    dispatch(getLastMsgThunk());
    setShowActionMenu(false);
  };

  const archiveChatHandler = async (payload: { senderId: string; receiverId: string; toArchive: boolean }) => {
    await dispatch(archiveChatThunk(payload));
    dispatch(getLastMsgThunk());
    setShowActionMenu(false);
  };

  const deleteChatHandler = async () => {
    await dispatch(deleteChatThunk(selectedRow));
    dispatch(getLastMsgThunk());
    setDeleteModal(false);
    setShowActionMenu(false);
  };

  const tabpaneValues = ["Chats", "Contacts"];

  return (
    <div className={`w-[112.5%] md:w-80 ${showMobChat && "hidden"} md:block -my-6 py-6 -ml-6 shadow-right `}>
      <InputWithIcon icon={<IoSearch />} placeholder="Search Here" className="mx-6 md:mx-2 mb-4" onChange={searchHandler} />
      {showArchive ? (
        <div className="flex items-center font-bold gap-2 pl-1 mb-12 w-80 ">
          <IoIosArrowBack className="text-2xl cursor-pointer" onClick={() => setShowArchive(false)} />
          <Typography variant="h2"> Archived Chat</Typography>
        </div>
      ) : (
        <Tabpane selectedTab={selectedTab} setSelectedTab={setSelectedTab} values={tabpaneValues} />
      )}

      {archivedChatList?.length > 0 && !showArchive && selectedTab == 1 && (
        <div onClick={() => setShowArchive(true)} className="bg-[#fff9f3] flex gap-3 -mt-5 mb-9 py-3 items-center rounded-md pl-4 cursor-pointer">
          <img src={archive} className="w-6 h-6" />
          <Typography variant="h2">Archived Chat</Typography>
          <Circle content={archivedChatList?.length} />
        </div>
      )}

      <div className={`overflow-y-scroll -mt-8 overscroll-x-none h-[28rem] custom-polifyx-scrollbar  ${!showArchive && "-mb-3"}`}>
        {loading ? (
          <ChatSkeleton />
        ) : selectedTab == 1 ? (
          lastChatListArr?.length > 0 ? (
            lastChatListArr.map((obj: any) => {
              const profilePicUrl = s3Url + obj?.userData[0]?._id + "/" + obj?.userData[0]?.profilePic;
              const isChatPinned = obj.userData[0]?.chatAction?.pinnedBy && obj.userData[0]?.chatAction?.pinnedBy.includes(currentUser._id);
              const isChatArchived = obj.userData[0]?.chatAction?.archiveBy && obj.userData[0]?.chatAction?.archiveBy.includes(currentUser._id);
              const isImage = ["image/png", "image/jpeg", "image/jpg"].includes(obj?.file?.type);
              if ((isChatArchived && !showArchive) || (!isChatArchived && showArchive)) return;
              return (
                <>
                  <div onClick={() => chatHandler(obj)} className={`flex justify-between relative gap-3 hover:bg-gray-200 px-6 md:px-2 cursor-pointer py-3 ${selectedChat == obj.userData[0] && "bg-gray-200"}`}>
                    <div className="flex w-full gap-2 relative">
                      <DisplayPicCard img={obj?.userData[0]?.profilePic ? profilePicUrl : profileDefault} online={obj.isOnline} />
                      <div>
                        <div className="flex gap-3 items-center">
                          <Typography variant="h5" className="font-bold ">
                            {obj?.userData[0]?.name}
                          </Typography>
                          {isChatPinned && <img src={pinChat} className="w-4 -mt-1" />}
                          <Typography className="font-bold text-sm  text-gray-600">{dayjs(obj?.createdAt).format("MMM DD")}</Typography>
                        </div>
                        <div className="flex justify-between">
                          <Typography className="text-gray-600 truncate w-44 ">
                            {obj?.isMedia ? (
                              <span className="flex gap-1 items-center">
                                {isImage ? <FaImage /> : <FaFile />}
                                <p className="w-40 truncate"> {obj?.file?.name}</p>
                              </span>
                            ) : (
                              obj?.message
                            )}
                          </Typography>
                          {obj?.unseenCount ? <div className={`bg-orange-400 absolute -right-9 text-white h-7  px-1.5 py-0.5 border-4 border-white  rounded-full text-xs font-semibold`}>{obj?.unseenCount}</div> : ""}
                        </div>
                      </div>
                    </div>
                    <BsThreeDots
                      className="text-2xl z-10"
                      onClick={(e) => {
                        e.stopPropagation();
                        actionMenuHandler(obj);
                      }}
                    />

                    <Menu showMenu={showActionMenu == obj._id} onClose={() => setShowActionMenu("")} className="w-48 top-12 right-0 md:right-4 z-50">
                      <ul>
                        <li
                          onClick={(e) => {
                            e.stopPropagation();
                            archiveChatHandler({ senderId: currentUser._id, receiverId: obj._id, toArchive: !isChatArchived });
                          }}
                          className="flex items-center gap-3 py-1 pl-2 rounded-md cursor-pointer hover:bg-[#fff9f3] hover:text-polyfixTheme "
                        >
                          <img src={archive} />
                          <Typography>{isChatArchived ? "Unarchive Chat" : "Archive Chat"}</Typography>
                        </li>
                        <li
                          onClick={(e) => {
                            e.stopPropagation();
                            pinChatHandler({ senderId: currentUser._id, receiverId: obj._id, toPin: !isChatPinned });
                          }}
                          className="flex items-center gap-3 py-1 pl-2 rounded-md cursor-pointer hover:bg-[#fff9f3] hover:text-polyfixTheme "
                        >
                          <img src={pinChat} className="w-5" />
                          <Typography>{!isChatPinned ? "Pin Chat" : "Unpin Chat"}</Typography>
                        </li>
                        <li
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedRow({ senderId: currentUser._id, receiverId: obj._id });
                            setDeleteModal(true);
                          }}
                          className="flex items-center gap-3 py-1 pl-2 rounded-md cursor-pointer hover:bg-[#fff9f3] hover:text-polyfixTheme "
                        >
                          <img src={deleteChat} className="w-4" />
                          <Typography className="pl-1 ">Delete Chat</Typography>
                        </li>
                      </ul>
                    </Menu>
                  </div>
                  <Divide className="border-t-gray-300 ml-2 md:ml-0" />
                </>
              );
            })
          ) : (
            <Typography className="flex justify-center items-center h-full">No Chat Found</Typography>
          )
        ) : (
          contactList &&
          contactList.map((obj: any) => {
            const profilePicUrl = s3Url + obj?._id + "/" + obj?.profilePic;
            return (
              <>
                <div onClick={() => chatHandlerContact(obj)} className="flex  items-center gap-3 hover:bg-gray-200  px-4  md:px-2 cursor-pointer py-3 ">
                  <DisplayPicCard img={obj?.profilePic ? profilePicUrl : profileDefault} />
                  <Typography variant="h5" className="font-bold ">
                    {obj.name}
                  </Typography>
                </div>
                <Divide className="border-t-gray-300" />
              </>
            );
          })
        )}
      </div>
      <DeleteChat showDeleteModal={showDeleteModal} setDeleteModal={setDeleteModal} deleteChatHandler={deleteChatHandler} />
    </div>
  );
};

export default ChatList;
