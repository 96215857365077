import Container from "../../component/common/Container";
import Tabpane from "../../component/common/Tabpane";
import ButtonOutlineIcon from "../../component/semantic/Button";
import Typography from "../../component/semantic/Typography";
import Card from "./component/Card.notification";
import EmptyNotification from "../../assets/icons/basic/EmptyNoti.svg";
import React, { useEffect, useState } from "react";
import Pagination from "../../component/common/Pagination";
import { getCurrentUser } from "../../helpers/currentUser";
import { useAppDispatch, useAppSelector } from "../../hooks/redux_toolkit";
import { clearNotificationThunk, userNotificationThunk } from "../../store/thunk/Notification.thunk";
import Spinner from "../../component/common/Spinner";
import dayjs from "dayjs";
import { daysTimestamp8 } from "../../helpers/constants/constants";

const Notification = () => {
  const [notificationList, setNotificationList] = useState<any>();
  const [selectedTab, setSelectedTab] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const currentDate = dayjs().startOf("day").valueOf();

  const pageSize = 10;
  const dispatch = useAppDispatch();

  const user: any = getCurrentUser();

  const notificationSliceData = useAppSelector((state) => state.rootReducer.notification);

  useEffect(() => {
    dispatch(userNotificationThunk({ userId: user?._id, size: pageSize, page: currentPage - 1, date: selectedTab == 1 ? currentDate : currentDate - daysTimestamp8 }));
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(1);
    dispatch(userNotificationThunk({ userId: user?._id, size: pageSize, page: 0, date: selectedTab == 1 ? currentDate : currentDate - daysTimestamp8 }));
  }, [selectedTab]);

  useEffect(() => {
    if (notificationSliceData.userNotification?.data?.notifications) {
      setNotificationList(notificationSliceData.userNotification?.data?.notifications);
    }
  }, [notificationSliceData]);

  const TabValues = ["Current Date", "Last 7 Days"];

  const emptyNotificationCard = (
    <div className="flex flex-col justify-center items-center h-[20rem]">
      <img src={EmptyNotification} />
      <Typography variant="h2" className="text-gray-400">
        No new notification
      </Typography>
    </div>
  );

  const clearNotification = async () => {
    await dispatch(clearNotificationThunk());
    dispatch(userNotificationThunk({ userId: user?._id, size: pageSize, page: 1, date: selectedTab == 1 ? currentDate : currentDate - daysTimestamp8 }));
  };

  return (
    <>
      <div id="mob-filter" className="font-semibold px-2 mx-4 mb-4 block lg:hidden">
        <p className="font-extrabold text-xl">Notification</p>
        <div className="flex justify-between mt-2">
          <Typography className="text-customgray text-sm md:text-base">All your notifications are listed here</Typography>
          <Typography variant="h2" className="text-polyfixTheme cursor-pointer" onClick={clearNotification}>
            Clear All
          </Typography>
        </div>
      </div>

      <Container>
        <header className="hidden lg:flex justify-between items-center mb-4">
          <span>
            <Typography variant="h3">Notifications</Typography>
            <Typography className="text-customgray mt-2">All your notifications are listed here</Typography>
          </span>
          <ButtonOutlineIcon color="polyfixTheme" onClick={clearNotification} className="hover:bg-polyfixTheme hover:text-white" title="Clear All" />
        </header>
        <Tabpane selectedTab={selectedTab} setSelectedTab={setSelectedTab} values={TabValues} />
        {
          <>
            {notificationSliceData.loading ? (
              <Spinner />
            ) : (
              <>
                {notificationList && notificationList?.length > 0 ? (
                  <>
                    <div className="mt-8 mb-8 ">
                      {notificationList.map((content: any, i: any) => (
                        <Card key={i} content={content} />
                      ))}
                    </div>
                    <Pagination itemsPerPage={pageSize} totalItems={notificationSliceData.userNotification?.data?.count} setCurrentPage={setCurrentPage} currentPage={currentPage} />
                  </>
                ) : (
                  emptyNotificationCard
                )}
              </>
            )}
          </>
        }
      </Container>
    </>
  );
};

export default Notification;
