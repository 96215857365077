import * as Yup from "yup";

export const profileSchema = Yup.object({
  name: Yup.string().min(2, "Name must be greater than 2 character").max(50, "Name must be less than 50 character").required("Name is required!"),
  mobile: Yup.string()
    .matches(/^[6789]\d{9}$/, "Please Enter a valid mobile number")
    .required("Mobile number is required!"),
  email: Yup.string()
    .matches(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/, "Please enter a valid Email")
    .required("Email is required!"),
  alternateEmails: Yup.string().test("isValidEmails", "Please enter valid email addresses separated by commas!", async (value) => {
    if (!value) return true;
    const emailList = value.split(",").map((email) => email.trim());
    for (let i = 0; i < emailList?.length; i++) {
      const isValidEmail = await Yup.string()
        .matches(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/)
        .isValid(emailList[i]);
      if (!isValidEmail) {
        return false;
      }
    }
    return true;
  }),
  address: Yup.string().required("Address is required!"),
  dob: Yup.date().max(new Date(), "Date of Birth cannot be in the future").required("Date of Birth is required!"),
  gender: Yup.string(),
  stateName: Yup.string().required("State is required"),
  districtName: Yup.string().required("District is required").notOneOf(["Select District"]),
  pincode: Yup.number().min(100000, "Please enter a valid 6-digit pin code!").max(999999, "Please enter a valid 6-digit pin code!").required("Pin code is required!"),
  aadhaarNumber: Yup.string()?.length(12, "Aadhar Number must be of exactly 12 digits").required("Aadhar number is required!"),
  natureOfBusiness: Yup.string(),
  areaOfOperation: Yup.array(),
  yearsOfExperience: Yup.number().positive("Please enter a valid year").max(50, "Experience cannot be greater than 50").required("Experience is required!"),
  companyName: Yup.array().min(1, "Please select company name"),
  panNumber: Yup.string()
    .matches(/^[a-zA-Z]{5}\d{4}[a-zA-Z]$/, "Please Enter a valid pan number!")
    .required("Pan number is required!"),
  agentCode: Yup.string(),
  bankName: Yup.string(),
  accountNumber: Yup.string(),
  branch: Yup.string(),
  ifsc: Yup.string()?.length(11, "Please enter a valid 11-digit IFSC code!"),
  gstNumber: Yup.string().matches(/^\d{2}[a-zA-Z]{5}\d{4}[a-zA-Z]\d[a-zA-Z]\d$/, "Please Enter a valid gst Number!"),
  otherCompanyName: Yup.string().when(["companyName"], (companyName, schema) => {
    return companyName && companyName[0].includes("Other") ? schema.required("Please provide other company name") : schema;
  })
});

export const personalSchema = Yup.object({
  name: Yup.string().min(2, "Name must be greater than 2 character").max(50, "Name must be less than 50 character").required("Name is required!"),
  mobile: Yup.string()
    .matches(/^[6789]\d{9}$/, "Please Enter a valid mobile number")
    .required("Mobile number is required!"),
  email: Yup.string()
    .matches(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/, "Please enter a valid Email")
    .required("Email is required!"),
  alternateEmails: Yup.string().test("isValidEmails", "Please enter valid email addresses separated by commas!", async (value) => {
    if (!value) return true;
    const emailList = value.split(",").map((email) => email.trim());
    for (let i = 0; i < emailList?.length; i++) {
      const isValidEmail = await Yup.string()
        .matches(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/)
        .isValid(emailList[i]);
      if (!isValidEmail) {
        return false;
      }
    }
    return true;
  }),
  address: Yup.string().required("Address is required!"),
  dob: Yup.date().max(new Date(), "Date of Birth cannot be in the future").required("Date of Birth is required!"),
  gender: Yup.string(),
  stateName: Yup.string().required("State is required"),
  districtName: Yup.string().required("District is required").notOneOf(["Select District"]),
  pincode: Yup.number().min(100000, "Please enter a valid 6-digit pin code!").max(999999, "Please enter a valid 6-digit pin code!").required("Pin code is required!"),
  aadhaarNumber: Yup.string()?.length(12, "Aadhar Number must be of exactly 12 digits").required("Aadhar number is required!")
});

export const professionalDetailsSchema = Yup.object({
  // natureOfBusiness: Yup.req(),
  areaOfOperation: Yup.array(),
  yearsOfExperience: Yup.number().positive("Please enter a valid year").max(50, "Experience cannot be greater than 50").required("Experience is required!"),
  companyName: Yup.array().min(1, "Please select company name"),
  panNumber: Yup.string()
    .matches(/^[a-zA-Z]{5}\d{4}[a-zA-Z]$/, "Please Enter a valid pan number!")
    .required("Pan number is required!"),
  agentCode: Yup.string(),
  otherCompanyName: Yup.string().when(["companyName"], (companyName, schema) => {
    return companyName && companyName[0].includes("Other") ? schema.required("Please provide other company name") : schema;
  })
});

export const bankSchema = Yup.object({
  bankName: Yup.string(),
  accountNumber: Yup.string(),
  branch: Yup.string(),
  ifsc: Yup.string()?.length(11, "Please enter a valid 11-digit IFSC code!")
  // gstNumber: Yup.string().matches(/^\d{2}[a-zA-Z]{5}\d{4}[a-zA-Z]\d[a-zA-Z]\d$/, "Please Enter a valid gst Number!")
});
