import React, { useEffect, useState } from "react";
import Filter from "./components/FIlter";
import { useAppDispatch, useAppSelector } from "../../hooks/redux_toolkit";
import { getAllUsersThunk } from "../../store/thunk/AllUser.thunk";
import ActionTable from "../../component/table/ActionTable";
import { allUserHeading, allUserKeys, sortAllUserKeys } from "../../component/table/TableConstants";
import Container from "../../component/common/Container";
import Spinner from "../../component/common/Spinner";
import Pagination from "../../component/common/Pagination";
import { actionModalTypes } from "../../helpers/constants/interfaceTypes";
import actionIcon from "../../assets/icons/basic/ActionIcon.svg";
import EditDetails from "./components/EditDetails";
import Typography from "../../component/semantic/Typography";
import { setSortedDataUsers } from "../../store/slices/AllUsers.slice";

const AllUsers = () => {
  const userData = useAppSelector((state) => state.rootReducer.AllUsers);
  const [selectedRow, setSelectedUser] = useState<any>();
  const [currentPage, setCurrentPage] = useState(1);
  const [openActionModal, setOpenActionModal] = useState(false);
  const [filter, setFilter] = useState({ search: "", startDate: "", endDate: "" });
  const [toggleApiCall, setToggleApiCall] = useState(false);

  const pageSize = 10;

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getAllUsersThunk({ page: filter?.search ? 1 : currentPage, size: pageSize, search: filter?.search ?? "", startDate: filter?.startDate ?? "", endDate: filter?.endDate ?? "" }));
  }, [currentPage, filter, toggleApiCall]);

  const actions: actionModalTypes = [
    {
      image: <img src={actionIcon} className="cursor-pointer h-6 w-6" />,
      tooltip: "Edit User Details",
      onClickFunction: () => setOpenActionModal(true)
    }
  ];

  const handleFilterChange = (newFilter: { search: string; startDate: string; endDate: string }) => {
    setFilter(newFilter);
  };

  return (
    <>
      <Filter onFilterChange={handleFilterChange} setFilter={setFilter} />
      <Container customClassName="mt-6">
        <Typography variant="h1">All Users</Typography>
        <Typography className="text-gray-600 mb-6">List of all Pending Partners, Associates and Advisors</Typography>
        {userData.loading ? <Spinner /> : <ActionTable sortedDataAction={setSortedDataUsers} sortKey={sortAllUserKeys} actions={actions} selectedRow={setSelectedUser} tableData={userData.usersList?.data?.userList} tableHeading={allUserHeading} tableKeys={allUserKeys} />}
        <span className="my-2">&nbsp;</span>
        <Pagination currentPage={currentPage} itemsPerPage={pageSize} setCurrentPage={setCurrentPage} totalItems={userData.usersList?.data?.userCount} />
        <EditDetails showModal={openActionModal} setOpenActionModal={setOpenActionModal} data={selectedRow} toggleApiCall={toggleApiCall} setToggleApiCall={setToggleApiCall} />
      </Container>
    </>
  );
};

export default AllUsers;
