import * as Yup from "yup";

export const reportSchema = Yup.object({
  reportType: Yup.string(),
  type: Yup.string().required("Please Select Date Type"),
  startDate: Yup.string().required("Please Select Start Date"),
  endDate: Yup.string()
    .required("Please Select End Date")
    .test("is-greater", "End date must be greater than start date", function (value, { parent }) {
      const startDate = parent.startDate;
      const endDate = value;
      if (!startDate || !endDate) {
        return true;
      }
      return new Date(endDate) > new Date(startDate); // Compare dates
    }),
  status: Yup.string().test("conditional-validation", "Please Select Complaint Status", function (value, { parent }) {
    const reportType = parent.reportType;
    if (reportType !== "User Report") {
      return typeof value === "string" && value.trim() !== "";
    }
    return true;
  }),
  userId: Yup.string().required("Please Select User Id")
});
export const reportSchemav2 = Yup.object({
  startDate: Yup.string().required("Please Select Start Date"),
  endDate: Yup.string()
    .required("Please Select End Date")
    .test("is-greater", "End date must be greater than start date", function (value, { parent }) {
      const startDate = parent.startDate;
      const endDate = value;
      if (!startDate || !endDate) {
        return true;
      }
      return new Date(endDate) > new Date(startDate); // Compare dates
    })
});
export const reportSchemav3 = Yup.object({
  startDate: Yup.string().required("Please Select Start Date"),
  endDate: Yup.string()
    .required("Please Select End Date")
    .test("is-greater", "End date must be greater than start date", function (value, { parent }) {
      const startDate = parent.startDate;
      const endDate = value;
      if (!startDate || !endDate) {
        return true;
      }
      return new Date(endDate) > new Date(startDate); // Compare dates
    }),
  userId: Yup.string().required("Please Select User Id")
});
export const passwordSchema = Yup.object({
  oldPassword: Yup.string().trim().required("Old Password is required"),
  newPassword: Yup.string().trim().required("New Password is required")
});

export const newCaseSchema = Yup.object({
  name: Yup.string()
    .trim()
    .required("Policyholder name is required ")
    .matches(/^[A-Za-z\s]+$/, "Please enter a valid name"),
  email: Yup.string()
    .trim()
    .required("Policyholder email is required")
    .matches(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/, "Please enter a valid Email"),
  phone: Yup.string()
    .trim()
    .required("Policyholder phone is required")
    .matches(/^[6789]\d{9}$/, "Please Enter a valid mobile number"),
  problemStatement: Yup.string().trim().required("Problem statement is required")
});

export const reachSchema = Yup.object({
  name: Yup.string()
    .trim()
    .required(" Please enter your name ")
    .matches(/^[A-Za-z\s]+$/, "Please enter a valid name"),
  email: Yup.string()
    .trim()
    .required("Please enter your email")
    .matches(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/, "Please enter a valid Email"),
  phone: Yup.string()
    .trim()
    .required("Please enter your phone")
    .matches(/^[6789]\d{9}$/, "Please Enter a valid mobile number")
});
