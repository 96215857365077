import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../helpers/axios";
import { baseUrl } from "../../helpers/constants/defaultUrls";

export const leadStatusThunk = createAsyncThunk("report/leadStatusThunk", async () => {
  const response = await axios.get(baseUrl + `v1/lead/status/list`);
  return response.data;
});

export const reportDataThunkComplaint = createAsyncThunk("report/reportDataThunkComplaint", async (payload: any) => {
  const response = await axios.post(baseUrl + `v1/lead/report/sendV2?page=${payload.currentPage - 1}&size=${payload.pageSize}`, payload.body);
  return response.data;
});

export const reportDataThunkLead = createAsyncThunk("report/reportDataThunkLead", async (payload: any) => {
  const response = await axios.post(baseUrl + `v1/lead/report/sendLeadData?page=${payload.currentPage - 1}&size=${payload.pageSize}`, payload.body);
  return response.data;
});

export const reportDataThunkUser = createAsyncThunk("report/reportDataThunkUser", async (payload: any) => {
  const response = await axios.post(baseUrl + `v1/user/generate/userData?page=${payload.currentPage - 1}&size=${payload.pageSize}`, payload.body);
  return response.data;
});

export const reportDataThunkActiveUser = createAsyncThunk("report/reportDataThunkActiveUser", async (payload: any) => {
  const response = await axios.post(baseUrl + `v1/user/report/activeData?page=${payload.currentPage - 1}&size=${payload.pageSize}`, payload?.body || {});
  return response.data;
});

export const reportDataThunkSettled = createAsyncThunk("report/reportDataThunkSettled", async (payload: any) => {
  const response = await axios.post(baseUrl + `v2/insurance/generate/settledCase?page=${payload.currentPage - 1}&size=${payload.pageSize}`, payload?.body || {});
  return response.data;
});

export const downloadReportThunkSettled = createAsyncThunk("report/downloadReportThunkSettled", async (payload: any) => {
  const response = await axios.post(baseUrl + `v2/insurance/download/settledReport`, payload);
  return response.data;
});

export const reportDataThunkKyp = createAsyncThunk("report/reportDataThunkKyp", async (payload: any) => {
  const response = await axios.post(baseUrl + `v1/user/generate/kypReport?page=${payload.currentPage - 1}&size=${payload.pageSize}`, payload?.body || {});
  return response.data;
});

export const reportDataThunkKypConsolidated = createAsyncThunk("report/reportDataThunkKypConsolidated", async (payload: any) => {
  const response = await axios.post(baseUrl + `v1/user/generate/sendTotalKypData?page=${payload.currentPage - 1}&size=${payload.pageSize}`, payload?.body || {});
  return response.data;
});

export const downloadReportThunkKyp = createAsyncThunk("report/downloadReportThunkKyp", async (payload: any) => {
  const response = await axios.post(baseUrl + `v1/user/download/kypReport`, payload);
  return response.data;
});

export const downloadReportThunkKypConsolidated = createAsyncThunk("report/downloadReportThunkKypConsolidated", async (payload: any) => {
  const response = await axios.post(baseUrl + `v1/user/download/downloadTotalKypData`, payload);
  return response.data;
});

export const downloadReportThunkComplaint = createAsyncThunk("report/downloadReportThunkComplaint", async (payload: any) => {
  const response = await axios.post(baseUrl + "v1/lead/report/downloadV2", payload);
  return response.data;
});

export const downloadReportThunkLead = createAsyncThunk("report/downloadReportThunkLead", async (payload: any) => {
  const response = await axios.post(baseUrl + "v1/lead/report/downloadLeadData", payload);
  return response.data;
});

export const downloadReportThunkUser = createAsyncThunk("report/downloadReportThunkUser", async (payload: any) => {
  const response = await axios.post(baseUrl + "v1/user/download/reportV2", payload);
  return response.data;
});

export const downloadReportThunkActiveUser = createAsyncThunk("report/downloadReportThunkActiveUser", async (payload: any) => {
  const response = await axios.post(baseUrl + "v1/user/report/active", payload);
  return response.data;
});
