import React, { FC, useRef, useState } from "react";
import Container from "../../../component/common/Container";
import { CiFilter } from "react-icons/ci";
import Typography from "../../../component/semantic/Typography";
import ButtonOutline from "../../../component/semantic/Button";
import ReactSelect from "react-select";
import { filterStyles } from "../../../helpers/constants/styles";
// import { CustomMenu } from "../../dashboard/components/ReactSelectCustom";
import CustomInput from "../../../component/semantic/Input";
import CustomMenu from "./CustomMenu";
import InputIcon from "../../../component/semantic/InputIconMob";

interface FilterProps {
  onFilterChange: (filter: { search: string; startDate: string; endDate: string }) => void;
  setFilter: any;
}

const Filter: FC<FilterProps> = ({ onFilterChange }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  // const [selectedDate2, setSelectedDate2] = useState<DateOptionType | null>({ label: "Date" });
  const [selectedDate, setSelectedDate] = useState<{ startDate: string; endDate: string; label: "Date" } | null>(null);
  const [search, setSearch] = useState("");
  const [showRange, setShowRange] = useState<boolean>(false);

  const selectRef: any = useRef();

  const handleSearch = () => {
    const trimmedSearch = search.trim();
    onFilterChange({
      search: trimmedSearch.length > 0 ? trimmedSearch : "",
      startDate: selectedDate?.startDate || "",
      endDate: selectedDate?.endDate || ""
    });
  };

  const handleClear = () => {
    setSearch("");
    setSelectedDate(null);
    onFilterChange({ search: "", startDate: "", endDate: "" });
  };

  return (
    <>
      <Container customClassName="hidden md:block">
        <div className="flex gap-5 items-center w-full">
          <div className="flex w-[90%] items-center gap-5">
            <div className="flex gap-4">
              <CiFilter className="text-[#FAAA52]" size={20} />
              <Typography className="w-20">Filter By</Typography>
            </div>
            <ReactSelect
              className="w-[55%] cursor-pointer"
              closeMenuOnSelect={false}
              isSearchable={false}
              ref={selectRef}
              onFocus={() => setIsMenuOpen(true)}
              onBlur={() => setIsMenuOpen(false)}
              menuIsOpen={isMenuOpen}
              placeholder="Date"
              styles={filterStyles}
              value={{ label: selectedDate?.label || "Date", value: "" }}
              components={{
                Menu: (props) => (
                  <CustomMenu
                    {...props}
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                    closeMenu={() => {
                      setIsMenuOpen(false);
                      selectRef.current?.blur();
                    }}
                    showRange={showRange}
                    setShowRange={setShowRange}
                  />
                )
              }}
            />
            <div className="w-full rounded border">
              <CustomInput
                placeholder="Enter Your Search"
                className="w-full"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onKeyDown={(e: any) => {
                  if (e.key === "Enter") {
                    handleSearch();
                  }
                }}
              />
            </div>
          </div>
          <div className="flex justify-between gap-2">
            <ButtonOutline title="Search" color="polyfixTheme" className="bg-polyfixTheme text-white" onClick={handleSearch} />
            <ButtonOutline title="Clear Filter" color="polyfixTheme" className="hover:bg-polyfixTheme text-polyfixTheme hover:text-white" onClick={handleClear} />
          </div>
        </div>
      </Container>
      <div className="flex md:hidden w-full flex-col gap-1 px-6">
        <div className="flex justify-between mt-2">
          <span className="flex gap-3">
            <CiFilter className="text-polyfixTheme bg-[#faa95221] rounded p-[2px]" size={20} />
            <Typography className="text-customgray">Filter By</Typography>
          </span>
          <Typography variant="h2" className="text-polyfixTheme cursor-pointer" onClick={handleClear}>
            Clear Filters
          </Typography>
        </div>
        <InputIcon
          placeholder="Name/Mobile/Email"
          onClickFunction={handleSearch}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSearch();
            }
          }}
        />
        <ReactSelect
          className="w-full mt-2 cursor-pointer"
          closeMenuOnSelect={false}
          isSearchable={false}
          ref={selectRef}
          onFocus={() => setIsMenuOpen(true)}
          onBlur={() => setIsMenuOpen(false)}
          menuIsOpen={isMenuOpen}
          placeholder="Date"
          styles={filterStyles}
          value={{ label: selectedDate?.label || "Date", value: "" }}
          components={{
            Menu: (props) => (
              <CustomMenu
                {...props}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                closeMenu={() => {
                  setIsMenuOpen(false);
                  selectRef.current?.blur();
                }}
                showRange={showRange}
                setShowRange={setShowRange}
              />
            )
          }}
        />
      </div>
    </>
  );
};

export default Filter;
