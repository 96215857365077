import React, { useState } from "react";
import ActionHandler from "./ActionHandler";

interface TableProps {
  tableHeading: string[];
  tableKeys: string[];
  tableData: any[];
  selectedRow: any;
  actions: any;
  setTableData?: any;
  actionTwo: any;
}
const ActionTable: React.FC<TableProps> = ({ tableHeading, tableKeys, tableData, actions, actionTwo, selectedRow }) => {
  const [showTooltip, setShowToolTip] = useState<any>([-1, -1]);
  const [showMenu, setShowMenu] = useState(-1);

  return (
    <div
      className="overflow-x-scroll w-full mr-6"
      style={{
        scrollbarWidth: "thin",
        scrollbarColor: "#D7DBEC transparent",
        msOverflowStyle: "none",
        overflowX: "scroll",
        overflowY: "hidden",
        borderRadius: "16px"
      }}
    >
      <table className="w-full">
        <thead>
          <tr className="border-b-2 whitespace-nowrap">
            {tableHeading.map((data: any, i: number) => {
              if (i == 0) return;
              return (
                <th key={i} className="pr-1 md:pr-3 py-2 md:py-4 text-sm md:text-base  ">
                  <div className="flex gap-2 px-4 min-w-32">
                    <p className="font-extrabold">{data}</p>
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className="text-gray-600 w-full text-center">
          {tableData && tableData?.length > 0 ? (
            tableData.map((data, index) => (
              <tr key={index} className="border-b-2 text-center">
                {tableKeys.map((key, i) => {
                  if (i === 0) return null;
                  if (key == "action-one") return <ActionHandler index={index} actions={actions} data={data} selectedRow={selectedRow} setShowToolTip={setShowToolTip} showTooltip={showTooltip} showMenu={showMenu} setShowMenu={setShowMenu} />;
                  if (key == "action-two") return <ActionHandler index={index} actions={actionTwo} data={data} selectedRow={selectedRow} setShowToolTip={setShowToolTip} showTooltip={showTooltip} showMenu={showMenu} setShowMenu={setShowMenu} />;
                  if (key === "isActive") return <td> {data[key] ? <span className="bg-[#D9F4EE] text-[#1BA785] p-1 px-2 rounded">Active</span> : <span className="bg-[#F1EAEA] text-[#8C7072] p-1 px-2 rounded">Not Active</span>} </td>;

                  const cellValue = data[key];

                  return (
                    <td key={i} className="text-left pr-6 py-4 ">
                      {cellValue || "-"}
                    </td>
                  );
                })}
              </tr>
            ))
          ) : (
            <td colSpan={tableHeading?.length} className="text-center text-xl pt-2">
              No data found
            </td>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ActionTable;
