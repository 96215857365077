import React from "react";
import { InputProps } from "./Input";
import { FiSearch } from "react-icons/fi";

interface InputWithIconProps extends InputProps {
  onClickFunction: any;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

const InputIcon: React.FC<InputWithIconProps> = ({ onChange, onKeyDown, onClickFunction, ...rest }) => {
  return (
    <div className={`relative w-full rounded-lg overflow-hidden`}>
      <input {...rest} onChange={onChange} onKeyDown={onKeyDown} className="appearance-none h-12 rounded-lg border focus:ring-2 focus:outline-none focus:ring-blue-300 w-full py-2 px-3 pr-10 text-gray-700 leading-tight focus:shadow-outline" type="text" />
      <div onClick={onClickFunction} className="absolute bg-polyfixTheme inset-y-0 w-12 right-0 justify-center flex items-center ">
        <FiSearch />
      </div>
    </div>
  );
};

export default InputIcon;
