import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { useFormik } from "formik";
import { useAppSelector } from "../../../../hooks/redux_toolkit";
import { Option, MultiValueLabel } from "../../../dashboard/components/ReactSelectCustom";
import Modal from "../../../../component/common/Modal";
import FormSelect from "../../../../component/semantic/FormSelect";
import Typography from "../../../../component/semantic/Typography";
import ButtonOutline from "../../../../component/semantic/Button";
import CloseIcon from "../../../../assets/icons/gallery/close.svg";
import FormInput from "../../../../component/semantic/FormInput";
import { polyfixSelect, customMultiValue } from "../../../../helpers/constants/styles";
import { editUserSchema } from "../../../../helpers/schemas/EditUsers.Schema";
import { toInputDate } from "../../../../helpers/utils";
import FormTextArea from "../../../../component/semantic/FormTextArea";
import { getStateAndDistrict } from "../../../../store/thunk/Common.thunk";
import { allStates as statesArray } from "../../../../helpers/constants/constants";

interface ModalProps {
  showEditUserModal: boolean;
  setEditUserModal: any;
  selectedUserData: any;
  updateUserData: any;
}

const EditUserDetails: FC<ModalProps> = ({ showEditUserModal, setEditUserModal, selectedUserData, updateUserData }) => {
  const dispatch = useDispatch<any>();
  const { stateName, districtName, areaOfOperation } = selectedUserData;

  const [formValues, setFormValue] = useState<any>();
  const [selectedState, setSelectedState] = useState<any>();
  const [districtValues, setDistrictValues] = useState<any>();
  const [selectedDistrict, setSelectedDistrict] = useState<any>();
  const [operationState, setOperationState] = useState<any>(areaOfOperation && areaOfOperation.map((obj: any) => ({ label: obj, value: obj })));

  // const [selectedBuisness, setSelectedBuisness] = useState<any>(selectedUserData?.natureOfBusiness && { label: selectedUserData.natureOfBusiness, value: selectedUserData.natureOfBusiness });

  const onDobChange = (e: any) => {
    new Date(e.target.value).setMilliseconds(0);
    new Date(e.target.value).setSeconds(0);
    new Date(e.target.value).setMinutes(0);
    new Date(e.target.value).setHours(0);
    const year = new Date(e.target.value).getFullYear();
    setFormValue({ ...values, dob: e.target.value, age: new Date().getFullYear() - year });
  };

  const { values, handleSubmit, handleBlur, handleChange, touched, errors, setFieldValue }: any = useFormik({
    initialValues: formValues,
    validationSchema: editUserSchema,
    enableReinitialize: true,
    onSubmit: async (values: any) => {
      values = {
        ...values,
        address: {
          fullAddress: values.address
        },
        // companyName: values.companyName.includes("Other") ? values.companyName : values.companyName.map((company: any) => company?.name),
        ...(values.dob ? { dob: new Date(values.dob).toISOString() } : "")
      };
      delete values.bankName;
      delete values.branch;
      delete values.accountNumber;
      delete values.ifsc;

      updateUserData(values);
      setEditUserModal(false);
    }
  });

  const formProps = { values, handleBlur, handleChange, touched, errors, setFieldValue };

  const allStates = useAppSelector((state) => state.rootReducer.common.stateDistrict?.data);
  const allStateList = statesArray.map((state: any) => ({ label: state, value: state }));

  // const natureOfBusinessList = useAppSelector((state) => state.rootReducer.profile.natureOfBuisness?.data);
  // let natureOfBuisnessArray;
  // if (natureOfBusinessList) {
  //   natureOfBuisnessArray = natureOfBusinessList.map((business: any) => ({ label: business, value: business }));
  // }

  useEffect(() => {
    if (allStates?.length > 0 && stateName) {
      const initialSelectedState = allStates.find((obj: any) => obj.name === stateName);
      setSelectedState(initialSelectedState);
      const initialSelectedDistrict = initialSelectedState.districts
        .filter((obj: any) => {
          return obj === districtName;
        })
        .map((obj: any) => ({ label: obj, value: obj }));

      setSelectedDistrict(initialSelectedDistrict);
    }
  }, [allStates, stateName]);

  useEffect(() => {
    const districtsOptions = selectedState?.districts?.map((district: any) => ({
      label: district,
      value: district
    }));
    setDistrictValues(districtsOptions);
  }, [selectedState]);

  useEffect(() => {
    if (selectedUserData) {
      setFormValue({
        _id: selectedUserData?._id,
        name: selectedUserData?.name,
        companyName: selectedUserData?.companyName && selectedUserData?.companyName?.length > 0 ? (selectedUserData?.companyName[0] ? selectedUserData?.companyName[0].trim() : "") : "",
        areaOfOperation: selectedUserData?.areaOfOperation,
        email: selectedUserData?.email,
        mobile: selectedUserData?.mobile,
        natureOfBusiness: selectedUserData?.natureOfBusiness,
        address: selectedUserData?.address?.fullAddress,
        about: selectedUserData?.about,
        stateName: selectedUserData?.stateName,
        agentCodeDoc: selectedUserData?.agentCodeDoc,
        districtName: selectedUserData?.districtName,
        pincode: selectedUserData?.pincode,
        otherCompanyName: selectedUserData?.otherCompanyName,
        gender: selectedUserData?.gender,
        dob: selectedUserData?.dob,
        gstNumber: selectedUserData?.gstNumber,
        panNumber: selectedUserData?.panNumber,
        aadhaarNumber: selectedUserData?.aadhaarNumber,
        website: selectedUserData?.website,
        age: selectedUserData.dob ? new Date().getFullYear() - new Date(selectedUserData.dob).getFullYear() : ""
      });
    }
  }, [selectedUserData]);

  useEffect(() => {
    // dispatch(natureOfBusiness());
    // dispatch(allCompanyList());
    dispatch(getStateAndDistrict());
  }, []);

  return (
    <Modal showModal={showEditUserModal} onClose={() => setEditUserModal(false)} className="flex relative flex-col p-5 w-[60%] h-[80%] z-[99]">
      <img src={CloseIcon} className="absolute text-3xl text-gray-400 -right-3 -top-3 cursor-pointer" onClick={() => setEditUserModal(false)} />
      <div className="m-4 p-2 overflow-auto">
        <Typography variant="h1">Edit User</Typography>
        <Typography variant="h2" className="font-medium text-gray-500 mb-3">
          Update user data from here.
        </Typography>
        <Typography variant="h2" className="font-medium">
          You're editing profile of {selectedUserData?.name} ({selectedUserData?.userName})
        </Typography>
        <br />

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <FormInput name="name" formProps={formProps} label="Name *" />
          <FormInput name="email" formProps={formProps} label="Email Id *" />
          <FormInput name="mobile" limit={10} formProps={formProps} label="Mobile Number *" />
          <FormSelect name="gender" label="Gender" formProps={formProps} options={["None", "Male", "Female"]} />
          <FormInput name="dob" type="date" max="today" value={toInputDate(formProps.values?.dob)} formProps={formProps} label="Date of Birth" onChange={(e: any) => onDobChange(e)} />
          <FormInput name="age" formProps={formProps} label="Age" disabled />
          <FormInput name="gstNumber" formProps={formProps} label="GST Number" />
          <FormInput name="panNumber" limit={10} formProps={formProps} label="PAN Number" />
          <FormInput name="aadhaarNumber" limit={12} formProps={formProps} label="Aadhaar Number" />
          <span className="row-span-2 mb-4">
            <FormTextArea name="address" formProps={formProps} label="Address (as per Aadhaar Card)" />
          </span>
          <span>
            <Typography className="font-normal">State</Typography>
            <Select
              options={allStates}
              isSearchable={true}
              value={selectedState}
              getOptionLabel={(option) => option?.name}
              getOptionValue={(option) => option?.name}
              styles={polyfixSelect}
              name="stateName"
              onChange={(data: any) => {
                setSelectedState(data);
                formProps.setFieldValue("stateName", data.name);
              }}
            />
          </span>
          <span>
            <Typography className="font-normal">District</Typography>
            <Select
              options={districtValues}
              isSearchable={true}
              value={selectedDistrict}
              styles={polyfixSelect}
              onChange={(data: any) => {
                setSelectedDistrict(data);
                formProps.setFieldValue("districtName", data.label);
              }}
            />
            {formProps.errors?.districtName && <span className="mt-1 text-red-600 font-semibold text-sm">Please Select District</span>}
          </span>
          <FormInput name="natureOfBusiness" formProps={formProps} label="Nature of Business" />
          {/* <span>
            <Typography className="font-normal">Nature of Business</Typography>
            <Select
              options={natureOfBuisnessArray && natureOfBuisnessArray}
              styles={polyfixSelect}
              placeholder="Enter Nature of Buisness"
              onChange={(option: any) => {
                formProps.setFieldValue("natureOfBusiness", option.label);
                setSelectedBuisness(option);
              }}
              value={selectedBuisness}
            />
          </span> */}
          <span>
            <Typography className="font-normal">Area of Operations</Typography>
            <Select
              options={[{ label: "Pan India", value: "Pan India" }, ...allStateList]}
              isSearchable={false}
              isMulti
              name="areaOfOperation"
              closeMenuOnSelect={false}
              placeholder="Select Area of Operations"
              value={operationState}
              styles={{ ...polyfixSelect, ...customMultiValue }}
              hideSelectedOptions={false}
              components={{
                Option: (props) => <Option {...props} checkColor="white" />,
                MultiValueLabel
              }}
              onChange={(option: any) => {
                formProps.setFieldValue(
                  "areaOfOperation",
                  option.map((obj: any) => obj.label)
                );
                if (option.find((obj: any) => obj.label === "Pan India")) {
                  setOperationState({ label: "Pan India", value: "Pan India" });
                  return;
                }
                setOperationState(option);
              }}
            />
          </span>

          <FormInput name="pincode" limit={6} formProps={formProps} label="Pincode" />
          <FormInput name="companyName" formProps={formProps} label="Company" />
          <FormInput name="website" formProps={formProps} label="Website URL" />
          <span className="row-span-2 mb-4">
            <FormTextArea name="about" formProps={formProps} label="About" />
          </span>
        </div>
      </div>
      <div className="flex justify-end">
        <ButtonOutline
          title="Cancel"
          className="hover:bg-[#382713] hover:text-[#FEFFFE] bg-[#FEFFFE] text-[#382713] border-[#382713] rounded-md w-32 mb-2"
          onClick={() => {
            setEditUserModal(false);
          }}
        />

        <ButtonOutline title="Update User" type="submit" className="bg-polyfixTheme text-black w-32 mb-2" onClick={handleSubmit} />
      </div>
    </Modal>
  );
};

export default EditUserDetails;
