import React, { useEffect, useRef, useState } from "react";
import { CiFilter } from "react-icons/ci";
import ContainerTwo from "../../../component/common/Container";
import Typography from "../../../component/semantic/Typography";
import ButtonOutlineIcon from "../../../component/semantic/Button";
import ReactSelect from "react-select";
import { useAppDispatch } from "../../../hooks/redux_toolkit";
import { dashboardBarStats, dashboardCardStats } from "../../../store/thunk/Dashboard.thunk";
import { Option, MultiValueLabel, CustomMenu } from "./ReactSelectCustom";
import { customStyles, filterStyles, polyfixSelect } from "../../../helpers/constants/styles";
import { getCurrentUser } from "../../../helpers/currentUser";
import { getUserDropdownThunk } from "../../../store/thunk/Common.thunk";
import { todayDateTimestamp } from "../../../helpers/constants/constants";
import dayjs from "dayjs";

interface OptionType {
  value: string;
  label: string;
}
interface DateOptionType {
  value?: {
    from: number;
    to: number;
  };
  label: string;
}

function Filter() {
  const dispatch = useAppDispatch();
  const [userDatas, setUserDatas] = useState<OptionType[]>([]);
  const [selectedDate, setSelectedDate] = useState<DateOptionType | null>({ label: "30 Days" });
  const [optionSelected, setSelectedOption] = useState<any[]>([]);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [showRange, setShowRange] = useState<boolean>(false);
  const currentUser = getCurrentUser();

  const clearFilter = () => {
    setSelectedDate({ label: "Date" });
    setSelectedOption([]);
    dashboardCardApi();
    dispatch(dashboardBarStats({ leadStatus: "", claimStatus: "", users: [] }));
  };

  useEffect(() => {
    if (currentUser?.userType == "Manager") {
      dispatch(getUserDropdownThunk({ type: "Manager" }))
        .unwrap()
        .then((data) => {
          setUserDatas(data?.data);
        });
    } else if (currentUser?.userType !== "Vendor") {
      dispatch(getUserDropdownThunk({ type: "Partner", level: currentUser?.hierarchicalLevel }))
        .unwrap()
        .then((data) => {
          setUserDatas(data?.data);
        });
    } else {
      dispatch(getUserDropdownThunk({ type: "Associate", level: currentUser?.hierarchicalLevel, parentId: currentUser?._id }))
        .unwrap()
        .then((data) => {
          setUserDatas(data?.data);
        });
    }
  }, []);

  useEffect(() => {
    dashboardCardApi();
  }, []);
  const selectRefMob = useRef<any>("");
  const selectRef = useRef<any>("");

  const dashboardCardApi = () => dispatch(dashboardCardStats({ from: dayjs(todayDateTimestamp - 2592000000).format("YYYY-MM-DD"), to: dayjs(todayDateTimestamp).format("YYYY-MM-DD") }));
  let debouncer: ReturnType<typeof setTimeout> | undefined = undefined;

  const userChangeHandler = (selectedOptions: any) => {
    setSelectedOption(selectedOptions);
    clearTimeout(debouncer);
    if (selectedOptions?.length == userDatas?.length) {
      setSelectedOption([]);
      dispatch(dashboardCardStats({ from: "", to: "", users: "All" }));
      dispatch(dashboardBarStats({ leadStatus: "", claimStatus: "", users: "All" }));
    } else if (selectedOptions.some((option: any) => option._id === "all")) {
      setSelectedOption([{ _id: "all", name: "All" }, ...userDatas]);
      dispatch(dashboardCardStats({ from: "", to: "", users: "All" }));
      dispatch(dashboardBarStats({ leadStatus: "", claimStatus: "", users: "All" }));
    } else {
      const mappedData = selectedOptions.filter((obj: any) => obj._id !== "all").map((obj: any) => obj._id);
      debouncer = setTimeout(() => {
        dispatch(dashboardCardStats({ from: "", to: "", users: mappedData }));
        dispatch(dashboardBarStats({ leadStatus: "", claimStatus: "", users: mappedData }));
      }, 500);
    }
  };

  return (
    <>
      <div id="mob-filter" className="block lg:hidden">
        <div className="font-semibold px-2">
          <p className="font-extrabold text-xl">Dashboard</p>
          <div className="flex justify-between mt-2">
            <span className="flex gap-3">
              <CiFilter className="text-polyfixTheme bg-[#faa95221] rounded p-[2px]" size={20} />
              <Typography className="text-customgray">Filter By</Typography>
            </span>
            <Typography variant="h2" className="text-polyfixTheme cursor-pointer" onClick={clearFilter}>
              Clear Filters
            </Typography>
          </div>
        </div>
        <div className="px-2 mt-2 gap-2 flex flex-col">
          <ReactSelect
            ref={selectRefMob}
            className="w-full cursor-pointer"
            closeMenuOnSelect={false}
            isSearchable={false}
            onFocus={() => setIsMenuOpen(true)}
            onBlur={() => setIsMenuOpen(false)}
            menuIsOpen={isMenuOpen}
            placeholder="Date"
            styles={filterStyles}
            value={{ label: selectedDate?.label, value: "" }}
            components={{
              Menu: (props) => (
                <CustomMenu
                  {...props}
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                  closeMenu={() => {
                    setIsMenuOpen(false);
                    selectRefMob.current?.blur();
                  }}
                  showRange={showRange}
                  setShowRange={setShowRange}
                />
              )
            }}
          />

          <ReactSelect
            options={(userDatas || [])?.length > 0 ? [{ _id: "all", name: "All" }, ...userDatas] : []}
            isMulti
            closeMenuOnSelect={false}
            // hideSelectedOptions={false}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option._id}
            components={{ Option, MultiValueLabel }}
            onChange={userChangeHandler}
            value={optionSelected}
            className="w-full "
            styles={{ ...polyfixSelect, ...customStyles }}
            placeholder="User"
          />
        </div>
      </div>
      <ContainerTwo customClassName="hidden lg:block">
        <div className="justify-between   lg:flex">
          <div className="flex justify-between gap-3 items-center">
            <CiFilter className="text-[#FAAA52]" size={20} />
            <Typography className="w-20">Filter By</Typography>
            <ReactSelect
              ref={selectRef}
              className="w-60 cursor-pointer"
              closeMenuOnSelect={false}
              isSearchable={false}
              onFocus={() => setIsMenuOpen(true)} // Open menu on focus
              onBlur={() => setIsMenuOpen(false)} // Close menu on blur
              menuIsOpen={isMenuOpen}
              placeholder="Date"
              styles={filterStyles}
              value={{ label: selectedDate?.label, value: "" }}
              components={{
                Menu: (props) => (
                  <CustomMenu
                    {...props}
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                    closeMenu={() => {
                      setIsMenuOpen(false);
                      selectRef.current?.blur();
                    }}
                    showRange={showRange}
                    setShowRange={setShowRange}
                  />
                )
              }}
            />

            <ReactSelect options={[{ _id: "all", name: "All" }, ...(userDatas ? userDatas : [])]} isMulti closeMenuOnSelect={false} hideSelectedOptions={false} getOptionLabel={(option) => option.name} getOptionValue={(option) => option._id} components={{ Option, MultiValueLabel }} onChange={userChangeHandler} value={optionSelected} className="w-60 " styles={{ ...polyfixSelect, ...customStyles }} placeholder="User" />
          </div>
          <ButtonOutlineIcon title="Clear Filter" color="polyfixTheme" className="hover:bg-polyfixTheme text-polyfixTheme hover:text-white" onClick={clearFilter} />
        </div>
      </ContainerTwo>
    </>
  );
}

export default Filter;
