import React, { ChangeEvent, useEffect, useState } from "react";
import { emailValidation, referralValidation } from "../../helpers/inputValidation";
import { useAppDispatch, useAppSelector } from "../../hooks/redux_toolkit";
import { invalidInput, validInput } from "../../store/slices/Profile.slice";
import { hideInputMessage } from "../../store/slices/Common.slice";

export type InputProps = {
  label?: string;
  name?: string;
  value?: string | number;
  placeholder?: string;
  type?: string;
  className?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  keyDownValidation?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onclick?: (event: React.MouseEvent) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  message?: string;
  validationType?: string;
  disabled?: boolean;
  min?: string;
  max?: string;
  required?: boolean;
  limit?: number;
  parentClassName?: string;
};

const CustomInput: React.FC<InputProps> = ({ onKeyDown, parentClassName, label, max, validationType = "", limit, name = "", value, onChange, type, min, ...rest }) => {
  const [isValid, setisValid] = useState<boolean>(true);
  const [errorMessage, seterrorMessage] = useState("");

  const currentDate = new Date().toISOString().split("T")[0];
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.rootReducer.common);

  useEffect(() => {
    if (isValid) {
      dispatch(validInput());
      dispatch(hideInputMessage());
    } else dispatch(invalidInput());
  }, [isValid]);

  const keyDownValidation = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;

    if (validationType === "referral") {
      setisValid(referralValidation(String(target.value)));
      seterrorMessage("Referral code should be between 6 and 32 character!");
      return;
    }

    if (validationType === "email") {
      setisValid(emailValidation(String(target.value)));
      seterrorMessage("Please Enter a valid Email !");
      return;
    }
    return;
  };
  return (
    <div className={parentClassName}>
      {label && (
        <label className="font-semibold" htmlFor={name}>
          {label}
        </label>
      )}
      <input
        {...rest}
        value={value}
        min={min}
        max={max && currentDate}
        onKeyDown={onKeyDown}
        onKeyUpCapture={keyDownValidation}
        onChange={(e: any) => {
          if (limit && e.target.value?.length > limit) return;
          if (onChange && typeof onChange === "function") {
            onChange(e);
          }
        }}
        type={type}
        name={name}
        className=" appearance-none border focus:ring-2 focus:outline-none focus:ring-blue-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:shadow-outline"
      />
      {!isValid || state.inputFieldName?.includes(name) ? <span className="mt-1 text-red-600 font-semibold text-sm">{errorMessage || state.inputFieldMessage}</span> : null}
    </div>
  );
};

export default CustomInput;
