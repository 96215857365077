import React from "react";
import { useAppSelector } from "../hooks/redux_toolkit";
import insaLogo from ".//InsaLogo.svg";

const PageNotFound = () => {
  const rolesLoading = useAppSelector((state) => state.rootReducer.login.loadingRoles);

  const partnerBaseUrl = process.env.REACT_APP_ENV !== "prod" ? `https://partnersadmin.${process.env.REACT_APP_ENV}.insurancesamadhan.com/` : "https://partnersadmin.insurancesamadhan.com/";

  const loadingPage = (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-100 bg-opacity-75">
      <div className="flex flex-col gap-10">
        <img src={insaLogo} className="w-80" />
        <div className="flex items-center justify-center space-x-2">
          <div className="w-6 h-6 bg-polyfixTheme rounded-full animate-bounce"></div>
          <div className="w-6 h-6 bg-polyfixTheme rounded-full animate-bounce animation-delay-200"></div>
          <div className="w-6 h-6 bg-polyfixTheme rounded-full animate-bounce animation-delay-400"></div>
        </div>
      </div>
    </div>
  );

  const errorPage = (
    <div className="page-404 pt-20	pb-20 box-border block">
      <div className="container w-full	mr-auto	ml-auto	box-border	block	">
        <div className="page text-center	box-border block ">
          <h2 className="heading text-6xl		font-bold	font- box-border	">This Page Does Not Exist</h2>
          <figure className="justify-center  box-border pt-8	 pb-8 mb-4 flex	">
            <img src="https://www.insurancesamadhan.com/images/404.png" alt="" className="box-border mr-12	ml-12 max-w-full align-middle		" />
          </figure>
          <p className="mt-0	my-4	">
            <a href={partnerBaseUrl} className="border-3 p-3	 pr-5	 pl-5 ml-4		bg-black	text-white		 border-black rounded-3xl	text-lg	font-semibold	 	">
              Back to Home
            </a>
          </p>
        </div>
      </div>
    </div>
  );

  return <div>{rolesLoading ? loadingPage : errorPage}</div>;
};

export default PageNotFound;
