import React, { FC, useState, useEffect } from "react";
import Modal from "../../../../component/common/Modal";
import Typography from "../../../../component/semantic/Typography";
import Select from "react-select";
import CloseIcon from "../../../../assets/icons/gallery/close.svg";
import { polyfixSelect } from "../../../../helpers/constants/styles";
import ButtonOutline from "../../../../component/semantic/Button";
import { useAppDispatch } from "../../../../hooks/redux_toolkit";
import { changeStatusHandler } from "../../../../store/thunk/AllLead.thunk";
// import ToastMessageService from "../../toastr.service";

interface ModalProps {
  changeStatus: boolean;
  setChangeStatus: any;
  selectedComplaint: any;
}

interface CheckboxInterface {
  name: string;
  completed: boolean;
  subtypes: CheckboxInterface[];
}

const statuses = [
  "Pending",
  "Accept",
  "ACCEPTED WITH PENDENCY",
  "Information Incomplete",
  "COMPLAINT FORM FILLED",
  "DRAFT MAIL GENERATED",
  "EXPERT APPROVED DRAFT MAIL",
  "Company",
  "IGMS",
  "EXPERT MAIL DRAFTED",
  "ESCALATION PENDING",
  "NO RESPONSE RECEIVED",
  "ESCALATION APPROVED",
  "COMPANY REQUIREMENT RECEIVED",
  "COMPANY REQUIREMENT SENT",
  "ESCALATION MAIL GENERATED",
  "COMPANY ESCALATED",
  "IGMS ESCALATED",
  "COMPANY/IGMS ESCALATED",
  "COMPANY/IGMS",
  "Ombudsman Pending",
  "OMBUDSMAN REQUIREMENT PENDING",
  "OMBUDSMAN REQUIREMENT PUSHED",
  "COMPLAINT FORM SENT",
  "Ombudsman without Legal",
  "OMBUDSMAN REQUIREMENT SENT",
  "FORM 6A RECEIVED",
  "FORM 6A PUSHED",
  "FORM 6A SENT",
  "HEARING DATE RECEIVED",
  "HEARING DONE",
  "AWARD ACCEPTED",
  "AWARD REJECTED",
  "Legal",
  "INVOICE RAISED",
  "REMINDER MAIL SENT",
  "Case assigned to Advocate",
  "Legal Draft for Approval with client",
  "Court filing pending with client",
  "MSME",
  "MSME Filing Done",
  "Written Arguments",
  "Reject",
  "COMPANY PAYMENT PENDING",
  "Ombudsman with Legal",
  "Resolved",
  "UnResolved",
  "Settled",
  "REIMBURSEMENT",
  "ASSERVICES",
  "WRITTEN OFF",
  "NON RESPONSIVE CUSTOMER",
  "INSA REJECTED",
  "LEGAL CONTRACT PENDING",
  "LEGAL CONTRACT SIGNED",
  "LEGAL QUERY RAISED",
  "LEGAL QUERY ANSWERED",
  "LEGAL DRAFT FOR APPROVAL",
  "COURT FILING POINTS",
  "COURT FILING PENDING BUCKET",
  "CASE FILING DONE",
  "CUSTOMER WITHDRAW",
  "INVOICE PROCESSING",
  "LEGAL RECOVERY FROM CUSTOMER",
  "LEGAL NOTICE RECEIVED",
  "LEGAL NOTICE SENT TO CUSTOMER",
  "LEGAL NOTICE SENT TO COMPANY",
  "LEGAL RECOVERY FROM COMPANY",
  "COURT ISSUED SUMMON",
  "COMPANY REPLY AWAITED",
  "REJOINDER NEED TO BE FILED",
  "AFFIDAVIT NEED TO BE FILED",
  "COMPLAINANT EVIDENCE",
  "DEFENDANT EVIDENCE",
  "SETTLEMENT PROPOSAL SUBMISSION PENDING",
  "SETTLEMENT PROPOSAL SUBMITTED",
  "AGRUMENTS",
  "FINAL ARGUMENTS",
  "ORDER AWAITED",
  "PARTIAL SETTLED"
];

const initialIncompleteInformationTypes: CheckboxInterface = {
  name: "Both Pending",
  completed: false,
  subtypes: [
    { name: "Documents Pending", completed: false, subtypes: [] },
    { name: "Details Pending", completed: false, subtypes: [] }
  ]
};

const initialDocumentsPendingHi: CheckboxInterface = {
  name: "Health Insurance",
  completed: false,
  subtypes: [
    { name: "Discharge Summary", completed: false, subtypes: [] },
    { name: "Rejection Letter", completed: false, subtypes: [] },
    { name: "Claim Bill", completed: false, subtypes: [] },
    { name: "Policy Doc", completed: false, subtypes: [] },
    { name: "Settlement Letter", completed: false, subtypes: [] },
    { name: "Communication Company", completed: false, subtypes: [] },
    { name: "Call Recording", completed: false, subtypes: [] },
    { name: "Previous Policy", completed: false, subtypes: [] },
    { name: "Dr Certificate", completed: false, subtypes: [] },
    { name: "RT_PCR", completed: false, subtypes: [] },
    { name: "Death Certificate", completed: false, subtypes: [] },
    { name: "FIR", completed: false, subtypes: [] },
    { name: "Post Mortem Report", completed: false, subtypes: [] }
  ]
};

const initialDocumentsPendingGi: CheckboxInterface = {
  name: "General Insurance",
  completed: false,
  subtypes: [
    { name: "Policy Book", completed: false, subtypes: [] },
    { name: "Rejection Letter", completed: false, subtypes: [] },
    { name: "Claim Bill", completed: false, subtypes: [] },
    { name: "Surveyor Report", completed: false, subtypes: [] },
    { name: "Settlement Letter", completed: false, subtypes: [] },
    { name: "Communication Company", completed: false, subtypes: [] },
    { name: "Call Recording", completed: false, subtypes: [] },
    { name: "Death Certificate", completed: false, subtypes: [] },
    { name: "Discharge Summ", completed: false, subtypes: [] },
    { name: "FIR", completed: false, subtypes: [] },
    { name: "Post Mortem Report", completed: false, subtypes: [] },
    { name: "Dr Report", completed: false, subtypes: [] }
  ]
};

const initialDocumentsPendingLi: CheckboxInterface = {
  name: "Life Insurance",
  completed: false,
  subtypes: [
    { name: "Policy Schedule", completed: false, subtypes: [] },
    { name: "Proof of Recording", completed: false, subtypes: [] },
    { name: "Rejection Letter", completed: false, subtypes: [] },
    { name: "Email Exchange", completed: false, subtypes: [] },
    { name: "Claim Form", completed: false, subtypes: [] },
    { name: "Communication Company", completed: false, subtypes: [] },
    { name: "Call Recording", completed: false, subtypes: [] },
    { name: "Discharge Summ", completed: false, subtypes: [] },
    { name: "Dr Report", completed: false, subtypes: [] },
    { name: "Death Certificate", completed: false, subtypes: [] },
    { name: "FIR", completed: false, subtypes: [] },
    { name: "Post Mortem Report", completed: false, subtypes: [] }
  ]
};

const ChangeStatus: FC<ModalProps> = ({ changeStatus, setChangeStatus, selectedComplaint }) => {
  const [currentStatus, setCurrentStatus] = useState("");
  const [reasonReject, setReasonReject] = useState("");
  const disptach = useAppDispatch();
  const [detailsPendingTextArea, setDetailsPendingTextArea] = useState("");
  const [incompleteInformationTypes, setIncompleteInformationTypes] = useState<CheckboxInterface>(initialIncompleteInformationTypes);
  const [documentsPendingHi, setDocumentsPendingHi] = useState<CheckboxInterface>(initialDocumentsPendingHi);
  const [documentsPendingGi, setDocumentsPendingGi] = useState<CheckboxInterface>(initialDocumentsPendingGi);
  const [documentsPendingLi, setDocumentsPendingLi] = useState<CheckboxInterface>(initialDocumentsPendingLi);
  const [documentSection, setDocumentSection] = useState(false);
  const [detailsSection, setDetailsSection] = useState(false);
  const [isHiCase, setIsHiCase] = useState(false);
  const [isGiCase, setIsGiCase] = useState(false);
  const [isLiCase, setIsLiCase] = useState(false);

  useEffect(() => {
    if (selectedComplaint) {
      setCurrentStatus(selectedComplaint.status);
      if (selectedComplaint.reasonReject) {
        setReasonReject(selectedComplaint.reasonReject);
      }
      if (selectedComplaint.policyTypeId && selectedComplaint.policyTypeId.name === "Health Insurance") {
        setIsHiCase(true);
      }
      if (selectedComplaint.policyTypeId && selectedComplaint.policyTypeId.name === "General Insurance") {
        setIsGiCase(true);
      }
      if (selectedComplaint.policyTypeId && selectedComplaint.policyTypeId.name === "Life Insurance") {
        setIsLiCase(true);
      }

      if (selectedComplaint.status === "INCOMPLETE INFORMATION") {
        if (selectedComplaint.incompleteDetails && selectedComplaint.incompleteDetails === true) {
          setDetailsSection(true);
          setDetailsPendingTextArea(selectedComplaint.incompleteDetailsContent);
          setIncompleteInformationTypes((prev: any) => ({
            ...prev,
            subtypes: prev.subtypes.map((subtype: any, index: any) => {
              return index === 1 ? { ...subtype, completed: true } : subtype;
            })
          }));
        }

        if (selectedComplaint.incompleteDocuments && selectedComplaint.incompleteDocuments === true) {
          setDocumentSection(true);
          setIncompleteInformationTypes((prev) => ({
            ...prev,
            subtypes: prev.subtypes.map((subtype, index) => {
              return index === 0 ? { ...subtype, completed: true } : subtype;
            })
          }));
          const updateSubtypesCompletion = (documentsPendingState: CheckboxInterface, incompleteDocs: string[]): CheckboxInterface => {
            const updatedDocumentsPendingState = {
              ...documentsPendingState,
              subtypes: documentsPendingState.subtypes.map((doc) => ({
                ...doc,
                completed: incompleteDocs.includes(doc.name)
              }))
            };
            return updatedDocumentsPendingState;
          };

          if (selectedComplaint.policyTypeId && selectedComplaint.policyTypeId.name === "Health Insurance") {
            const updatedDocumentsPendingHi = updateSubtypesCompletion(initialDocumentsPendingHi, selectedComplaint.incompleteDocs);
            setDocumentsPendingHi(updatedDocumentsPendingHi);
          }

          if (selectedComplaint.policyTypeId && selectedComplaint.policyTypeId.name === "General Insurance") {
            const updatedDocumentsPendingGi = updateSubtypesCompletion(initialDocumentsPendingGi, selectedComplaint.incompleteDocs);
            setDocumentsPendingGi(updatedDocumentsPendingGi);
          }

          if (selectedComplaint.policyTypeId && selectedComplaint.policyTypeId.name === "Life Insurance") {
            const updatedDocumentsPendingLi = updateSubtypesCompletion(initialDocumentsPendingLi, selectedComplaint.incompleteDocs);
            setDocumentsPendingLi(updatedDocumentsPendingLi);
          }
        }
      }
    }
  }, [selectedComplaint]);

  const closeModal = () => {
    setChangeStatus(false);
  };

  const handleChangeStatus = (selectedOption: any) => {
    setCurrentStatus(selectedOption.value);
    setReasonReject("");
    if (selectedOption.value === "INCOMPLETE INFORMATION") {
      setDetailsSection(false);
      setDocumentSection(false);
      setDetailsPendingTextArea("");
      setIncompleteInformationTypes(initialIncompleteInformationTypes);
      setDocumentsPendingHi(initialDocumentsPendingHi);
      setDocumentsPendingGi(initialDocumentsPendingGi);
      setDocumentsPendingLi(initialDocumentsPendingLi);
    }
  };

  const handleDetailsPendingTextArea = (event: any) => {
    setDetailsPendingTextArea(event.target.value);
  };

  const handleIncompleteInformationCheckboxChange = (event: any, subtypeIndex: any) => {
    const updatedIncompleteInformationTypes = { ...incompleteInformationTypes };
    const subtypes = [...updatedIncompleteInformationTypes.subtypes];
    subtypes[subtypeIndex] = { ...subtypes[subtypeIndex], completed: event.target.checked };

    if (subtypeIndex === 0 && event.target.checked) {
      setDocumentSection(true);
    } else if (subtypeIndex === 0 && !event.target.checked) {
      setDocumentSection(false);
      setDocumentsPendingHi(initialDocumentsPendingHi);
      setDocumentsPendingGi(initialDocumentsPendingGi);
      setDocumentsPendingLi(initialDocumentsPendingLi);
    }

    if (subtypeIndex === 1 && event.target.checked) {
      setDetailsSection(true);
    } else if (subtypeIndex === 1 && !event.target.checked) {
      setDetailsSection(false);
      setDetailsPendingTextArea("");
    }

    updatedIncompleteInformationTypes.subtypes = subtypes;
    setIncompleteInformationTypes(updatedIncompleteInformationTypes);
  };

  const handleCheckboxChange = (event: any, documentsPendingState: any, setDocumentsPendingState: any) => {
    const updatedDocumentsPendingState = { ...documentsPendingState };
    const updatedSubtypes = updatedDocumentsPendingState.subtypes.map((subtype: any) => {
      return subtype.name === event.target.name ? { ...subtype, completed: event.target.checked } : subtype;
    });

    updatedDocumentsPendingState.subtypes = updatedSubtypes;
    setDocumentsPendingState(updatedDocumentsPendingState);
  };

  const handleSubmit = () => {
    if (!currentStatus) {
      // Handle form validation if status is not selected
      return;
    }

    if (currentStatus === "INCOMPLETE INFORMATION") {
      if (!documentSection && !detailsSection) {
        // ToastMessageService.show("error", "Please mention whether document or details or both have incomplete information.");
        return;
      }

      if (detailsSection && (!detailsPendingTextArea || detailsPendingTextArea?.length <= 0)) {
        // ToastMessageService.show("error", "Details section text size should be greater than 0");
        return;
      }

      let flag = false;
      const checkDocumentsPending = (documentsPendingState: any) => {
        if (!documentsPendingState.completed) {
          for (let i = 0; i < documentsPendingState.subtypes?.length; i++) {
            if (documentsPendingState.subtypes[i].completed) {
              flag = true;
              break;
            }
          }
        }
      };

      if (documentSection) {
        if (isHiCase) {
          checkDocumentsPending(documentsPendingHi);
        } else if (isGiCase) {
          checkDocumentsPending(documentsPendingGi);
        } else {
          checkDocumentsPending(documentsPendingLi);
        }

        if (!flag) {
          //   ToastMessageService.show("error", "Please select any subtype of health, life or general insurance from documents list.");
          return;
        }
      }
    }

    const finalBody: any = {
      status: currentStatus,
      leadId: selectedComplaint.leadId?._id,
      reasonReject: "",
      incompleteDetails: false,
      incompleteDetailsContent: "",
      incompleteDocuments: false,
      incompleteDocs: []
    };

    if (currentStatus === "Reject") {
      finalBody.reasonReject = reasonReject;
    }

    if (currentStatus === "INCOMPLETE INFORMATION") {
      if (documentSection) {
        finalBody.incompleteDocuments = true;
        if (isHiCase) {
          finalBody.incompleteDocs = documentsPendingHi.subtypes.filter((subtype) => subtype.completed).map((subtype) => subtype.name);
        } else if (isGiCase) {
          finalBody.incompleteDocs = documentsPendingGi.subtypes.filter((subtype) => subtype.completed).map((subtype) => subtype.name);
        } else {
          finalBody.incompleteDocs = documentsPendingLi.subtypes.filter((subtype) => subtype.completed).map((subtype) => subtype.name);
        }
      }
      if (detailsSection) {
        finalBody.incompleteDetails = true;
        finalBody.incompleteDetailsContent = detailsPendingTextArea;
      }
    }

    // console.log('finalBody', finalBody);

    disptach(changeStatusHandler(finalBody));
  };

  return (
    <Modal showModal={changeStatus} onClose={closeModal} className="relative w-[35%] p-8 pb-4">
      <img src={CloseIcon} className="absolute text-3xl text-gray-400 right-[-15px] top-[-20px] cursor-pointer" onClick={() => closeModal()} />
      <div className="modal-header flex justify-between items-center border-b pb-2 mb-4">
        <Typography>Change Status</Typography>
      </div>
      <div className="modal-body mb-8">
        <Select options={statuses.map((status) => ({ value: status, label: status }))} value={{ value: currentStatus, label: currentStatus }} onChange={handleChangeStatus} styles={polyfixSelect} />

        {currentStatus === "Reject" && (
          <div className="mt-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">Reason for Rejection</label>
            <textarea className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" rows={4} value={reasonReject} onChange={(e) => setReasonReject(e.target.value)} />
          </div>
        )}

        {currentStatus === "INCOMPLETE INFORMATION" && (
          <>
            <div className="mt-4">
              <Typography>Information Type</Typography>
              {incompleteInformationTypes.subtypes.map((subtype, index) => (
                <label key={index} className="block text-gray-700 text-sm font-bold mb-2">
                  <input type="checkbox" className="mr-2 leading-tight" checked={subtype.completed} onChange={(event) => handleIncompleteInformationCheckboxChange(event, index)} />
                  {subtype.name}
                </label>
              ))}
            </div>

            {documentSection && (
              <>
                <div className="mt-4">
                  <Typography>Documents Pending</Typography>
                  {isHiCase && (
                    <>
                      <Typography>Health Insurance</Typography>
                      {documentsPendingHi.subtypes.map((subtype, index) => (
                        <label key={index} className="block text-gray-700 text-sm font-bold mb-2">
                          <input type="checkbox" className="mr-2 leading-tight" checked={subtype.completed} onChange={(event) => handleCheckboxChange(event, documentsPendingHi, setDocumentsPendingHi)} name={subtype.name} />
                          {subtype.name}
                        </label>
                      ))}
                    </>
                  )}
                  {isGiCase && (
                    <>
                      <Typography>General Insurance</Typography>
                      {documentsPendingGi.subtypes.map((subtype, index) => (
                        <label key={index} className="block text-gray-700 text-sm font-bold mb-2">
                          <input type="checkbox" className="mr-2 leading-tight" checked={subtype.completed} onChange={(event) => handleCheckboxChange(event, documentsPendingGi, setDocumentsPendingGi)} name={subtype.name} />
                          {subtype.name}
                        </label>
                      ))}
                    </>
                  )}
                  {isLiCase && (
                    <>
                      <Typography>Life Insurance</Typography>
                      {documentsPendingLi.subtypes.map((subtype, index) => (
                        <label key={index} className="block text-gray-700 text-sm font-bold mb-2">
                          <input type="checkbox" className="mr-2 leading-tight" checked={subtype.completed} onChange={(event) => handleCheckboxChange(event, documentsPendingLi, setDocumentsPendingLi)} name={subtype.name} />
                          {subtype.name}
                        </label>
                      ))}
                    </>
                  )}
                </div>
              </>
            )}

            {detailsSection && (
              <div className="mt-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">Details Pending</label>
                <textarea className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" rows={4} value={detailsPendingTextArea} onChange={handleDetailsPendingTextArea} />
              </div>
            )}
          </>
        )}
      </div>
      <div className="modal-footer flex justify-end mt-4">
        <ButtonOutline title="Change Status" onClick={handleSubmit} className={`bg-polyfixTheme text-white w-full md:w-42 mb-2`} />
      </div>
    </Modal>
  );
};

export default ChangeStatus;
