import { RouterProvider, createBrowserRouter } from "react-router-dom";
import React, { Suspense, useEffect, useState } from "react";
import allRoutes from "./routes/routes";
import { ToastContainer, toast } from "react-toastify";
import { MdOutlineClose } from "react-icons/md";
import useSocketService from "./services/Socket.service";
import { socketIOUrl } from "./helpers/constants/defaultUrls";
import { getCurrentUser } from "./helpers/currentUser";
import { useAppSelector, useAppDispatch } from "./hooks/redux_toolkit";
import checkAuthorizationService from "./services/CheckAuthorization.service";
import { getUserRoleThunk } from "./store/thunk/Login.thunk";

const App: React.FC = () => {
  const { emit } = useSocketService(socketIOUrl);
  const currentUser = getCurrentUser();
  const [routes, setRoutes] = useState(createBrowserRouter(allRoutes));

  const loginData = useAppSelector((state) => state.rootReducer.login);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (currentUser?.token) emit("set-user", currentUser.token);
  }, [currentUser]);

  useEffect(() => {
    if (currentUser?.token && !loginData.isNetworkError) {
      if (loginData.roles?.length === 0) {
        dispatch(getUserRoleThunk());
      }
      const updatedRoutes = allRoutes.filter((rt) => {
        if (rt.renderToAll) {
          return true;
        }
        const isAuthorized = checkAuthorizationService(loginData.roles, rt.authorizationRequired || []);
        return isAuthorized;
      });
      setRoutes(createBrowserRouter(updatedRoutes));
    }
  }, [loginData]);

  const successClassName1 = "bg-[#D5F4E5] text-[#143F2A] font-bold w-[23rem] md:w-[27rem] py-2 px-2 my-2  rounded-md absolute flex right-0 border border-[#95E3BE] ";
  const errorClassName = "bg-[#E57D66] text-white font-bold w-[23rem] md:w-[27rem] py-2 px-2 my-2  rounded-md absolute flex right-0 border border-[#E39E95] ";
  const warningClassName = "bg-yellow-500 text-white font-bold w-[23rem] md:w-[27rem] py-2 px-2 my-2  rounded-md absolute flex right-0 border border-yellow-600 ";
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <RouterProvider router={routes} />
      <ToastContainer
        hideProgressBar={true}
        icon={false}
        containerId={1}
        bodyStyle={{ height: "1rem" }}
        closeButton={
          <div className="flex items-center text-2xl cursor-pointer">
            <MdOutlineClose onClick={() => toast.dismiss()} />
          </div>
        }
        // eslint-disable-next-line react/prop-types
        toastClassName={(props: any) => (props?.type === "success" ? successClassName1 : props?.type === "warning" ? warningClassName : errorClassName)}
      />
    </Suspense>
  );
};

export default App;
